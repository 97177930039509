import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { VideosProvider as Provider } from '@shapeable/ui';

const VIDEOS_QUERY = graphql`
  query VideosQuery {
    platform {
      allVideos {
        typeLabel
        color { id value }
        id path slug name url __typename _schema { label pluralLabel }
        openGraph { description { plain } url title image { url url2x thumbnails { bubble { url url2x } card { url url2x } small { url url2x } } } }
        trend { id name path outlineNumber color { id value } }
        trends { id name slug path outlineNumber color { id value } }
        topics { id name slug path outlineNumber color { id value } }
        topic { id name slug path 
          trend {
            outlineNumber color { id value } }
          }
      }
    }
  }
`;

export const VideosProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(VIDEOS_QUERY), 'allVideos' ) }>{children}</Provider>;
};