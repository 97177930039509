import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { applyOpenGraphThumbnailSize, classNames } from '@shapeable/utils';
import { ComponentPropsProvider, EntityCardDefaultProps, PersonCard as CoreComponent, EntityCardProps, PersonCardImage, PersonCardMeta, useLang } from '@shapeable/ui';
import { Person } from '@shapeable/gesda-types';
import { useCurrentEdition } from '../../hooks/use-current-edition';
const cls = classNames('person-card');

// -------- Types -------->

// -------- Props -------->

export type PersonCardProps = Omit<EntityCardProps, 'entity'> & { 
  entity?: Person;
};

export const PersonCardDefaultProps: Omit<PersonCardProps, 'entity'> = {
  ...EntityCardDefaultProps,
  showColorStrip: true,
  showDescription: false,
  showTypeHeader: false,
  useEntityColorForBackground: true,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    // quick-fix for chevron color, until button is moved to color token system
    ${theme.FILL('dark')};
  `,
});


// -------- Components -------->

const My = {
  Container: styled(CoreComponent)<ContainerProps>`${ContainerStyles}`,
};

export const PersonCard: Shapeable.FC<PersonCardProps> = (props) => {
  const { className, ...cardProps } = props;

  const t = useLang();
  const entity: Person = applyOpenGraphThumbnailSize('bubbleLarge')(props.entity);
  const currentEdition = useCurrentEdition();
  return (
    <ComponentPropsProvider value={{
      Badge: {
        colors: {
          background: entity.color?.value
        },
      }
    }}>
      <My.Container {...cardProps} useEntityColorForBackground={currentEdition?.name === entity.badge} />
    </ComponentPropsProvider>
  )
};

PersonCard.defaultProps = PersonCardDefaultProps;
PersonCard.cls = cls;