import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Entity, OutlineEntity, Shapeable } from '@shapeable/types';
import { breakpoints, fill, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EntityDotLink, EntityDotLinkProps } from '@shapeable/ui';
const cls = classNames('sub-topic-outline-dot-link');

// -------- Types -------->

export type SubTopicOutlineDotLinkProps = Classable & HasChildren & Omit<EntityDotLinkProps, 'entity'> & {
  entity: OutlineEntity;
};

export const SubTopicOutlineDotLinkDefaultProps: Omit<SubTopicOutlineDotLinkProps, 'entity'> = {
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const OutlineNumberStyles = breakpoints({
  base: css`
    display: inline-block;
    margin-right: ${theme.UNIT(2)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled(EntityDotLink)`${ContainerStyles}`,
    OutlineNumber: styled.b.attrs(cls.attr('outline-number'))`${OutlineNumberStyles}`,
};

export const SubTopicOutlineDotLink: Shapeable.FC<SubTopicOutlineDotLinkProps> = (props) => {
  const { className, children, entity, ...rest } = props;
  const { outlineNumber, color } = entity;
  
  const dotProps = {
    ...(rest.dotProps || {}),
    color: (rest.dotProps && rest.dotProps.color) || (entity.color && entity.color.value) || 'primary',
    fill: 'FFF',
  };

  return (
    <My.Container entity={entity} {...rest} dotProps={dotProps} className={cls.name(className)}>
      <span>
      {
        outlineNumber &&
        <My.OutlineNumber>{outlineNumber}</My.OutlineNumber>
      }
      {children || entity.name}
      </span>
    </My.Container>
  )
};

SubTopicOutlineDotLink.defaultProps = SubTopicOutlineDotLinkDefaultProps;
SubTopicOutlineDotLink.cls = cls;