import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, EntityTypeName, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, SidebarButton, useAuth, useContributeForm, useEntity, useLang, useView } from '@shapeable/ui';
import { includes, without } from 'lodash';
import { classNames } from '@shapeable/utils';
import { ChatTextIcon, ChatTextIconGlyph, WorldMapIconGlyph } from '@shapeable/icons';
import { EntityMapToggles, useMapUtils } from '@shapeable/maps';
import { RadarChart } from '@shapeable/gesda-ui';
import { useRadarMap } from '../../hooks/use-radar-map';
import { ExplorerView } from '../../types';
import { isExplorerEntity } from '../../utils/is-explorer-entity';
const cls = classNames('sidebar-buttons');

// -------- Types -------->

export type SidebarButtonsProps = Classable & HasChildren & {
}

export const SidebarButtonsDefaultProps: SidebarButtonsProps = {
};

// -------- Styles -------->

const RadarIconStyles = breakpoints({
  base: css`
    display: none;
    width: 32px;
    height: 32px;
    margin-bottom: ${theme.UNIT(2)};
  `,
  tablet: css`
    display: flex;
  `,
});

const TogglesStyles = breakpoints({
  base: css`
    
  `,
});

const ExplorerMapStyles = breakpoints({
  base: css`
    display: flex;
  `,
  desktop: css`
    display: none;
  `,
});

const WorldMapStyles = breakpoints({
  base: css`
    
  `,
});


const WorldMapIconStyles = breakpoints({
  base: css`
    width: 36px;
    height: 22px;
    display: none;
    margin-bottom: ${theme.UNIT(3)};
  `,
  tablet: css`
    display: flex;
  `
});

const FeedbackFormStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    width: 32px;
    ${theme.H_DOT_BG('bright', 2, 6)};
  `,
});

const ChatBubbleStyles = breakpoints({
  base: css`
    height: 26px;
    width: auto;
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const ContributeLabelStyles = breakpoints({
  base: css`
    font-weight: 500;
    font-size: ${theme.FONT_SIZE(10)};
    margin-bottom: ${theme.UNIT(2)};
    font-family: ${theme.FONT('condensed')};
  `,
});


// -------- Components -------->

const My = {
  Container: React.Fragment,
    Toggles: styled(EntityMapToggles)`${TogglesStyles}`,
    ExplorerMap: styled(SidebarButton)`${ExplorerMapStyles}`,
    WorldMap: styled(SidebarButton)`${WorldMapStyles}`,
    WorldMapIcon: styled(WorldMapIconGlyph)`${WorldMapIconStyles}`,
    RadarIcon: styled(RadarChart)`${RadarIconStyles}`,

    FeedbackForm: styled(SidebarButton)`${FeedbackFormStyles}`,
      ChatIcon: styled(ChatTextIconGlyph)`${ChatBubbleStyles}`,
      ContributeLabel: styled.span`${ContributeLabelStyles}`,

};

export const SidebarButtons: Shapeable.FC<SidebarButtonsProps> = (props) => {
  const { className } = props;
  const entity = useEntity();
  const t = useLang();
  const map = useMapUtils();
  const radarMap = useRadarMap();
  const FeedbackForm = useContributeForm();

  const { activeView } = useView<ExplorerView>('radar');

  // const showRadar = isExplorerEntity(entity);

  return (
    <My.Container>
      {/* {
        !map.isVisible && showRadar &&
        <My.ExplorerMap isDisclosed={radarMap.isShown} onClick={() => { radarMap.toggle() }}>{t(radarMap.isShown ? 'Hide Radar' : 'Show radar')}</My.ExplorerMap>
      } */}
      {
        !map.isVisible && !radarMap.isShown &&
        <My.WorldMap onClick={map.showOnClick} icon={<My.WorldMapIcon/>}>{t('World Map')}</My.WorldMap>
      }
      {
        !map.isVisible && !radarMap.isShown &&
          <My.FeedbackForm onClick={FeedbackForm.showOnClick} icon={<My.ChatIcon size={14} />}>{t('Give Your Feedback')}</My.FeedbackForm>
      }
    </My.Container>
  )
};

SidebarButtons.cls = cls;
SidebarButtons.defaultProps = SidebarButtonsDefaultProps;