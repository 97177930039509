import { Entity, EntityTypeName, TContentNode, MenuItem } from "@shapeable/types";

export const DEFAULT_MAP_ENTITY_TYPE_NAMES: EntityTypeName[] = [
  'Person',
];

export const GptButtonSummary: TContentNode = {
  text: "‘RadarAI’ is an AI assistant specifically trained on GESDA’s Scientific Platforms and its knowledge archive. Choose to chat, or select a type of response."
};

const utilityMeuItem: MenuItem = {
  id: 'home',
  name: 'PDF',
  path: '/home',
  slug: 'home',
}

export const utlityMenuItems: MenuItem[] = [
  {
    id: 'gesda-2024',
    name: '2024 GESDA Radar (54MB)',
    path: '/2024.pdf',
    slug: 'gesda-2024-pdf',
  },
  {
    id: 'gesda-2023',
    name: '2023 GESDA Radar (30MB)',
    path: '/2023.pdf',
    slug: 'gesda-2023-pdf',
  },
  {
    id: 'gesda-2022',
    name: '2022 GESDA Radar (28MB)',
    path: '/2022.pdf',
    slug: 'gesda-2022-pdf',
  },
  {
    id: 'gesda-2021',
    name: '2021 GESDA Radar (21MB)',
    path: '/2021.pdf',
    slug: 'gesda-2021-pdf',
  },
];

export const linearNavigationItems: string[] = [
  "Page:/home",
  "Page:/2024-edition",
  "Page:/2024-edition/a-letter-from-the-chairmen",
  "Page:/2024-edition/about-the-science-breakthrough-radar",

  /*

  // https://gesda-web.netlify.app/2024-edition/top-trends-for-2024
  // https://gesda-web.netlify.app/2024-edition/acknowledgements
  // https://gesda-web.netlify.app/2024-edition/press-media
  // https://gesda-web.netlify.app/2024-edition/facts-and-figures
  // https://gesda-web.netlify.app/2024-edition/introductory-essay-gesd-as-approach-to-science-anticipation
  // https://gesda-web.netlify.app/2024-edition/highlights-of-the-2024-edition



  // https://gesda-web.netlify.app/ai-futures-peace-and-war
  // https://gesda-web.netlify.app/anticipating-the-future-of-research
  // https://gesda-web.netlify.app/the-relation-of-advanced-ai-to-the-future-of-peace-and-war
  // https://gesda-web.netlify.app/the-philosophical-lens-eco-augmentation-shaping-the-future-of-social-ecological-systems

  // https://gesda-web.netlify.app/2024-edition
  // https://gesda-web.netlify.app/invited-contributions/fungal-pandemics
  // https://gesda-web.netlify.app/future-of-social-science-and-the-humanities
  // https://gesda-web.netlify.app/synthetic-human-embryos
  // https://gesda-web.netlify.app/mathematics-for-humanity
  // https://gesda-web.netlify.app/space-and-global
  // https://gesda-web.netlify.app/superconductivity
  // https://gesda-web.netlify.app/nuclear-fusion-technology
  // https://gesda-web.netlify.app/the-future-of-multilateralism
  // https://gesda-web.netlify.app/the-future-of-trade
  
  


  */
  
];
