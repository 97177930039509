import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Badge, EntityBubbleImage, EntityTooltip, EntityValue, PersonCardMeta } from '@shapeable/ui';
import { Person } from '@shapeable/gesda-types';
const cls = classNames('person-tooltip');

// -------- Types -------->

// -------- Props -------->

export type PersonTooltipProps = Classable & HasChildren & { 
  entity?: Person;
};

export const PersonTooltipDefaultProps: Omit<PersonTooltipProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const BodyStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(3)};
    margin-top: ${theme.UNIT(3)};
    ${theme.H_DOT_BG('line', 2, 4)};
  `,
});

const ImageStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(4)};
    
  `,
});

const MetaStyles = breakpoints({
  base: css`
    
  `,
});

const BadgeStyles = breakpoints({
  base: css`
    
  `,
});

const BadgeContainerStyles = breakpoints({
  base: css`
    display: flex;
    padding-bottom: ${theme.UNIT(1)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled(EntityTooltip)<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
    Image: styled(EntityValue)`${ImageStyles}`,
    Badgecontainer: styled.div`${BadgeContainerStyles}`,
    Badge: styled(Badge)`${BadgeStyles}`,
    Meta: styled(PersonCardMeta)`${MetaStyles}`,
};

export const PersonTooltip: Shapeable.FC<PersonTooltipProps> = (props) => {
  const { className, children, entity } = props;
  
  return (
    <My.Container className={cls.name(className)} entity={entity} >
      <My.Body>
        {
          entity.badge &&
          <My.Badgecontainer style={{ display: 'flex' }}>
          <My.Badge>{entity?.badge}</My.Badge>
          </My.Badgecontainer>
        }
        <My.Meta entity={entity} />
      </My.Body>
    {children}
    </My.Container>
  )
};

PersonTooltip.defaultProps = PersonTooltipDefaultProps;
PersonTooltip.cls = cls;