import React from 'react';
import { PAGE_HOME, SiteSidebarProvider, usePageEntity } from '@shapeable/ui';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { WithAuthenticationRequiredOptions } from '@auth0/auth0-react';
import { Page } from '@shapeable/types';
import { LinearNavigationProvider } from './linear-navigation-provider';
import { SiteFooterProvider, SiteHeaderProvider, SiteSidebar } from '@shapeable/ui';

export const WITH_AUTHENTICATION_REQUIRED_OPTIONS: WithAuthenticationRequiredOptions = {
  loginOptions: {
    appState: { targetUrl: '/' },
  }
};

export const PAGE_EXPLORER: Page = {
  id: 'home',
  slug: 'home',
  path: '/',
};

const ExplorerProviderComponent: React.FC<any> = ({ children }) => {
  const page = usePageEntity();
  
  return (
    <SiteFooterProvider value={{ boundary: 'none' }}>
    <SiteHeaderProvider value={{ 
      variant: 'default',
      boundary: 'padding',
    }}>
    <SiteSidebarProvider value={{
      color: page?.color?.value || '#333333',
    }}>
    <LinearNavigationProvider>
      {children}
    </LinearNavigationProvider>
    </SiteSidebarProvider>
    </SiteHeaderProvider>
    </SiteFooterProvider>
  );
};

let authRequired = false;
export const ExplorerProvider = authRequired ? withAuthenticationRequired(ExplorerProviderComponent) : ExplorerProviderComponent;
