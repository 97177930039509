import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, Page } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EntityFilters, EntityGrid, ExplorerEntityGridLayout, NoResultsLayout, SearchBar, useTrends } from '@shapeable/ui';
import { SiteFooterLayout } from '@shapeable/ui';
import { patchTrendSocietyView } from '../../utils/transforms';
const cls = classNames('radar-actions-debates-layout');

// -------- Types -------->

export type ExplorerPulseOfSocietyLayoutProps = Classable & HasChildren & { 
  entity?: Page;
};

export const ExplorerPulseOfSocietyLayoutDefaultProps: Omit<ExplorerPulseOfSocietyLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const ExplorerPulseOfSocietyLayout: Shapeable.FC<ExplorerPulseOfSocietyLayoutProps> = (props) => {
  const { className } = props;
  const gridItems = useTrends().map(trend => patchTrendSocietyView(trend, 'society'));

  return (
    <My.Container 
      className={cls.name(className)}
      items={gridItems}
    />
  )
};

ExplorerPulseOfSocietyLayout.defaultProps = ExplorerPulseOfSocietyLayoutDefaultProps;
ExplorerPulseOfSocietyLayout.cls = cls;