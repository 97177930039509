import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ExplorerEntityGridLayout, useTrends } from '@shapeable/ui';
import { patchTrendView } from '../../utils/transforms';
const cls = classNames('explorer-pulse-of-impact-layout');

// -------- Types -------->

// -------- Props -------->

export type ExplorerPulseOfImpactLayoutProps = Classable & HasChildren & { 
};

export const ExplorerPulseOfImpactLayoutDefaultProps: Omit<ExplorerPulseOfImpactLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
};

export const ExplorerPulseOfImpactLayout: Shapeable.FC<ExplorerPulseOfImpactLayoutProps> = (props) => {
  const { className } = props;

  const gridItems = useTrends().map(trend => patchTrendView(trend, 'impact'));
  
  return (
    <My.Container 
      className={cls.name(className)} 
      items={gridItems}
      anyCardProps={{ showDescription: false }}
    />
  )
};

ExplorerPulseOfImpactLayout.defaultProps = ExplorerPulseOfImpactLayoutDefaultProps;
ExplorerPulseOfImpactLayout.cls = cls;