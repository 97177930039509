import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { RadarChart as CoreRadarChart, RadarChartProps as CoreRadarChartProps } from '@shapeable/gesda-ui';
import { Page } from '@shapeable/gesda-types';
import { EntityLink, IconLabel, useLang } from '@shapeable/ui';
import { ChevronRightIconGlyph } from '@shapeable/icons';
const cls = classNames('radar-chart');

// -------- Types -------->

// -------- Props -------->

export type RadarChartProps = Classable & HasChildren & CoreRadarChartProps & {
}

export const RadarChartDefaultProps: RadarChartProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    position: relative;
  `,
});

const RadarStyles = breakpoints({
  base: css`
    margin-top: -12px;
  `,
});

const LinkStyles = breakpoints({
  base: css`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    user-select: none;
  `,
});

const IconLabelStyles = breakpoints({
  base: css`
    ${theme.TRANSITION('opacity')};
    font-size: 0.7em;
    font-weight: 300;
    opacity: 0.4;
    border-radius: ${theme.UNIT(3)} 0 0 0;
    padding: ${theme.UNIT(3)} ${theme.UNIT(4)} ${theme.UNIT(3, 2)};
    background: ${theme.COLOR('bright-warm')};

    &:hover {
      opacity: 1;
    }
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Radar: styled(CoreRadarChart)`${RadarStyles}`,
    Link: styled(EntityLink)`${LinkStyles}`,
    IconLabel: styled(IconLabel)`${IconLabelStyles}`,
};

export const RadarChart: Shapeable.FC<RadarChartProps> = (props) => {
  const { className, ...radarChartProps } = props;

  const t = useLang();

  const methodologyPage: Page = {
    id: 'methodology',
    path: '/science-anticipation/methodology',
    name: 'View Methodology',
  };

  return (
    <My.Container className={cls.name(className)}>
      <My.Radar {...radarChartProps} />
      <My.Link entity={methodologyPage}>
        <My.IconLabel iconSize={12} reverse icon={<ChevronRightIconGlyph />}>
        {t('View Methodology')}
        </My.IconLabel>
      </My.Link>
    </My.Container>
  )
};

RadarChart.cls = cls;
RadarChart.defaultProps = RadarChartDefaultProps;