import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, TrendsProvider as Provider } from '@shapeable/ui';
import { sortBy } from 'lodash';

const TRENDS_QUERY = graphql`
  query TrendsQuery {
    platform {
      allTrends {
        id path slug name outlineNumber __typename
        typeLabel
        summary { text }
        openGraph { id title description { plain } image { id url url2x thumbnails { bubble { url url2x } card { id url url2x } } } }
        banner { id 
          image { url url2x width height thumbnails { card { url url2x } } } 
          thumbnail { url url2x width height thumbnails { card { url url2x } } }
        }
        color { id name value }
        _schema { label pluralLabel }
        topics { 
          id path outlineNumber slug name color { id value } 
          openGraph {
            title description { plain }
            image { url url2x }
          }
          subTopics {
            id path outlineNumber slug name color { id value }
            openGraph {
              title description { plain }
              image { url url2x }
            }
          }
        }
        feedEntries {
          id path slug name url
          published author 
          openGraph { url title description { text } image { url url2x thumbnails { card { url url2x } small { url url2x } } } }
        }
        whatDoPeopleSay {
          __typename id name path slug title subtitle pretitle 
          openGraph { image { url url2x thumbnails { card { url url2x } } } }
          type { slug }
          intro { id text }
          trend { id name slug path color { id value } }
          people {
            __typename id name slug position
            photo { id url url2x }
            organisation { id name slug }
          }
          moderators {
            __typename id name slug position
            photo { id url url2x }
            organisation { id name slug }
          }
        }
        whatDoPeopleDo {
          __typename id name path slug title subtitle pretitle 
          openGraph { image { url url2x thumbnails { card { url url2x } } } }
          orderNumber
          type { slug }
          trend { id name slug path color { id value } }
          intro { id text }
          people {
            __typename id name slug position
            photo { id url url2x }
            organisation { id name slug }
          }
          moderators {
            __typename id name slug position
            photo { id url url2x }
            organisation { id name slug }
          }
        }
        mediaAnalysis { 
          __typename id name path slug title subtitle pretitle 
          openGraph { image { url url2x thumbnails { card { url url2x } } } }
          type { slug }
          trend { id name slug path color { id value } }
          intro { id text }
          people {
            __typename id name slug position
            photo { id url url2x }
            organisation { id name slug }
          }
          moderators {
            __typename id name slug position
            photo { id url url2x }
            organisation { id name slug }
          }
        }
      }
    }
  }
`;

export const TrendsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(TRENDS_QUERY), 'allTrends' ), 'outlineNumber') }>{children}</Provider>;
};