import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ComponentPropsProvider, EntityFilters, SearchBar, SiteFooterLayout, useInfiniteScrollArray, GridLoader, useSearch, useView, NoResultsLayout, EntityGrid, ExplorerEntityGridLayout } from '@shapeable/ui';
import { classNames, entityNormalizedField, linkedMatchesFilter } from '@shapeable/utils';
import { compact, sortBy, flatMap } from 'lodash';
import { useCommunityFilters } from '../../hooks/use-community-filters';
import { usePeople } from '../../hooks/use-people';
import { Person } from '@shapeable/gesda-types';
const cls = classNames('radar-community-layout');

// -------- Types -------->

export type ExplorerCommunityLayoutProps = Classable & HasChildren & { 
};

export const ExplorerCommunityLayoutDefaultProps: Omit<ExplorerCommunityLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const FiltersStyles = breakpoints({
  base: css`
  `,
});



// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const ExplorerCommunityLayout: Shapeable.FC<ExplorerCommunityLayoutProps> = (props) => {
  const { className } = props;

  const view = 'radar';
  const { filters } = useView(view);
  const { countries = [], trends = [], topics = [], editions = [] } = filters;
  const allPeople = usePeople();
  const { selects } = useCommunityFilters();

  const gridItems = sortBy(allPeople.filter((person: Person) => (
    (editions.length == 0 || editions.includes(person.badge)) && 
    linkedMatchesFilter([person.country], countries) &&
    linkedMatchesFilter(compact(person.topics.filter(topic => topic.trend).map(topic => topic.trend)), trends) &&
    linkedMatchesFilter(compact(person?.topics), topics)
  )), entityNormalizedField("name"));

  return (
    <My.Container 
      view={view}
      showSearchBar
      className={cls.name(className)}
      items={gridItems}
      filters={
        <My.Filters view={view} selects={selects} />
      }
    />
  )
};

ExplorerCommunityLayout.defaultProps = ExplorerCommunityLayoutDefaultProps;
ExplorerCommunityLayout.cls = cls;