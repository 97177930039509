import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { PeopleProvider as Provider, platformProviderValue } from '@shapeable/ui';

const PEOPLE_QUERY = graphql`
  query PlatformPeopleQuery {
    platform {
      allPeople {
        typeLabel
        badge
        color { value }
        openGraph { title description { plain } image { url thumbnails { faceLarge { url url2x } bubbleLarge { url url2x } } } }
        id name slug path __typename _schema { label pluralLabel }
        organisation { 
          id name 
          location {
            __typename id slug name
            latitude longitude
          }
        }
        location {
          __typename id slug name
          latitude longitude
        }
        trends { 
          id name slug path __typename
        }
        topics {
          id name slug path __typename
          trend { id name slug __typename }
        }
        country {
          id name slug __typename
          latitude longitude
          openGraph { title image { url thumbnails { bubble { url url2x } } } }
          _schema { label pluralLabel }
        }
        linkedin
        position
        topicExpertise { id topic { color { value } } }
        hasConnectedTopics
      }
    }
  }`;

export const PeopleProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(PEOPLE_QUERY), 'allPeople' ) }>{children}</Provider>;
}
