import React from 'react';
import styled, { css } from 'styled-components';
import { Color, Shapeable } from '@shapeable/types';
import { EntityCard, EntityCardDefaultProps, EntityCardProps, EntityColorLabel, EntityStack, OutlineEntityNumber, OutlineNumber } from '@shapeable/ui';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Debate } from '../../types';
import { DebateCardMeta } from './debate-card-meta';
const cls = classNames('debate-card');

// -------- Types -------->

// -------- Props -------->

export type DebateCardProps = Omit<EntityCardProps, 'entity'> & { 
  entity?: Debate;
};

export const DebateCardDefaultProps: Omit<DebateCardProps, 'entity'> = {
  ...EntityCardDefaultProps,
  descriptionMaxLines: 4,
};

// -------- Child Component Props -------->

type ContainerProps = {
  
};

type DebateNumberProps = {
  _color?: Color;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const DebateNumberStyles = breakpoints({
  base: css`
    font-weight: 600;
    font-size: ${18/14}em;
    ${({ _color }: DebateNumberProps ) => css`
      color: ${theme.COLOR(_color?.value)};
    `}
  `,
});


// -------- Components -------->

const My = {
  Container: styled(EntityCard)<ContainerProps>`${ContainerStyles}`,
    DebateNumber: styled.span<DebateNumberProps>`${DebateNumberStyles}`,
};

export const DebateCard: Shapeable.FC<DebateCardProps> = (props) => {
  const { className, entity, ...cardProps } = props;

  const { trend, outlineNumber, color } = entity;

  return (
    <My.Container 
      {...cardProps}
      className={cls.name(className)}
      entity={entity}
      showDescription={false}
      showColorStrip={true}
      pretitle={<My.DebateNumber _color={color} >{outlineNumber}</My.DebateNumber>}
      meta={
        <DebateCardMeta color={trend?.color} entity={entity} />
      }
    />
  )
};

DebateCard.defaultProps = DebateCardDefaultProps;
DebateCard.cls = cls;