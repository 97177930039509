import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ExplorerProvider } from '../providers/explorer-provider';
import { ExplorerHomeBannerLayout } from './explorer-home-banner-layout';
import { ExplorerEntityDetailsLayout } from '@shapeable/ui';
import { ExplorerHomeViewLayout } from './explorer-home-view-layout';
import { useRadarMap } from '../../hooks/use-radar-map';
import { RadarChartMain } from '../elements/radar-chart-main';
import { RadarChart } from '../elements/radar-chart';
const cls = classNames('radar-home-layout');

// -------- Types -------->

export type ExplorerHomeLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerHomeLayoutDefaultProps: Omit<ExplorerHomeLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->


const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: ${theme.COLOR('bright')};
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    flex-grow: 1;

    .shp--entity-main-header {
      display: none;
    }
  `,
  desktop: css`
    
  `,
});

const RadarChartStyles = breakpoints({
  base: css`
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
    RadarChart: styled(RadarChart)`${RadarChartStyles}`,
};

export const ExplorerHomeLayout: Shapeable.FC<ExplorerHomeLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { isShown } = useRadarMap();
  


  return (
    <ExplorerProvider>

    <My.Container className={cls.name(className)}>
      <My.Layout
        asideMaxWidth={800}
        asideHeaderProps={{ navigationProps: { showIcon: false }}}
        entity={entity}
        isAsideSticky
        stickyTopOffset={106}
        asideIsRevealed={isShown}
        banner={
          <ExplorerHomeBannerLayout entity={entity} />
        }
        content={
          <ExplorerHomeViewLayout entity={entity} />
        }
        dataVizMain={
          <RadarChartMain />
        }

        // aside
        dataViz={
          <My.RadarChart initialY={10} animate />
        }
      />
      {children}
    </My.Container>
    </ExplorerProvider>
  )
};

ExplorerHomeLayout.defaultProps = ExplorerHomeLayoutDefaultProps;
ExplorerHomeLayout.cls = cls;