import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { EditionsProvider as Provider } from '../../components/context/editions-context';

const EDITIONS_QUERY = graphql`
  query EditionsQuery {
    platform {
      allEditions {
        id name slug path __typename _schema { label pluralLabel }
      }
    }
  }
`;

export const EditionsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(EDITIONS_QUERY), 'allEditions' ) }>{children}</Provider>;
}