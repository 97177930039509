import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, EntityWithCoordinates, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { MapBoxRefProvider, MapStateContext, useMapState, useMapUtils } from '@shapeable/maps';
import { useAppState, useOrganisations } from '@shapeable/ui';
import { EntityMapPanel as CoreEntityMapPanel } from '@shapeable/maps';
import { usePeople } from '../../hooks/use-people';
import { Person } from '@shapeable/gesda-types';
import { MapMarker } from '../elements/map-marker';
import { ValueMarker } from '../elements/value-marker';
import { SurveryValueTooltip } from '../elements/survery-value-tooltip';
import { MapLegend } from '../elements/map-legend';
import { MapLegendReveal } from '../elements/map-legend-reveal';
import { MapLegends } from '../elements/map-legends';

const cls = classNames('entity-map-panel');

// -------- Types -------->

export type MapProperties = {
  id: string;
  country: string;
  value: number;
  latitude: number;
  longitude: number;
}

export type DataWithCoordinates = {
  properties: MapProperties;
  type: string;
}

export type ClientDataInfo = {
  type: string;
  features: DataWithCoordinates[];
};


export type EntityMapPanelProps = Classable & HasChildren & {
}

export const EntityMapPanelDefaultProps: EntityMapPanelProps = {
};

// -------- Marker Styles -------->

const MarkerStyles = breakpoints({
  base: css`
    
  `,
});

const MapLegendStyles = breakpoints({
  base: css`
    position: absolute;
    top: 80px;
    left: 20px;
    z-index: 500;
  `,
});

const LegendCountriesStyles = breakpoints({
  base: css`
    position: absolute;
    top: 80px;
    left: 330px;
    z-index: 500;
  `,
});

const LegendSurveryValueStyles = breakpoints({
  base: css`
    position: absolute;
    top: 80px;
    left: 570px;
    z-index: 500;
  `,
});


// -------- Components -------->

const My = {
  Marker: styled(MapMarker)`${MarkerStyles}`,

  LegendReveal: styled(MapLegendReveal)`${MapLegendStyles}`,
  LegendCountries: styled(MapLegend)`${LegendCountriesStyles}`,
  LegendSurveyValue: styled(MapLegend)`${LegendSurveryValueStyles}`,

  MapLegends: styled(MapLegends)`${MapLegendStyles}`,

  ValueMarker: styled(ValueMarker)`${MarkerStyles}`,
  Tooltip: styled(SurveryValueTooltip)`${MarkerStyles}`,

};

export const EntityMapPanel: Shapeable.FC<EntityMapPanelProps> = (props) => {
  const { className } = props;

  const { deselect, zoom, selectedEntity, entityTypeIsVisible } = useMapUtils();
  const [ state = {}, setState, patchState ] = useMapState();
  const [ appState, setAppState, patchAppState ] = useAppState<{ isMapReady?: boolean }>();
  const [ mapState, patchMapState ] = useMapState(); 

  const people = usePeople();

  const toggleSelectedEntity: (entity: MapProperties) => void = (entity) => {
    patchState({ selectedEntity: (selectedEntity && selectedEntity.id === entity.id) ? null : entity });
  };

  const mapRef = React.useRef(null);
  const [geoJsonData, setGeoJsonData] = useState({});
  const [clientData, setClientData] = useState({});

  useEffect(() => {

    const fetchGeoJsonData = async () => {
      const response = await fetch('/countries-dataset.geojson');
      const geoJsonData = await response.json();
      return geoJsonData;
    };

    const fetchClientData = async () => {
      const response = await fetch('/gesda-heatmap-data.geojson');
      const clientData = await response.json();
      setClientData(clientData);
      return clientData;
    };

    const filterAndMergeData = async () => {
      const geoJsonData = await fetchGeoJsonData();
      const clientData = await fetchClientData();

      const clientDataMap = clientData.features.reduce((acc: any, feature: any) => {
        acc[feature.properties.country] = feature.properties.value;
        return acc;
      }, {});

      const filteredGeoJson = {
        ...geoJsonData,
        features: geoJsonData.features
          .filter((feature: any) => clientDataMap.hasOwnProperty(feature.properties.NAME_LONG))
          .map((feature: any) => ({
            ...feature,
            properties: {
              ...feature.properties,
              value: clientDataMap[feature.properties.NAME_LONG],
            }
          }))
      };

      setGeoJsonData(filteredGeoJson);
      
    };

    filterAndMergeData();
    
  }, []);

const onMapLoad = () => {
  const mapInstance = mapRef?.current?.getMap();

  if (mapInstance && geoJsonData) {
    mapInstance.addSource('countries', {
      type: 'geojson',
      data: geoJsonData,
    });

    mapInstance.addLayer({
      id: 'countries-layer',
      type: 'fill',
      source: 'countries',
      paint: {
        'fill-color': 'rgba(27, 177, 171, 0.7)',
        'fill-opacity': 0.8
      }
    });
  };

  return () => {
    if (mapInstance) {
      mapInstance.remove();
    }
  };
};

  const transformPeopleData = (people: Person[]) => {
    return people.map((person) => {
      const { location } = person;
      if (person.location) {
        const { latitude, longitude } = location;
        return { ...person, longitude, latitude };
      };
      return person;
    });
  };

  const items = [
    ...transformPeopleData(people),
  ];

  const markers = React.useMemo(() => {
    return ((clientData as ClientDataInfo)?.features || []).map((item) => {
      const { properties, type } = item;
      const { country, value, latitude, longitude, id } = properties;

      const zoomLevelForSize = Math.max(1, mapState.zoom);
      const markerSize = Math.min(100, Math.floor(zoomLevelForSize) * 11) * 0.8;

      const isSelected = id === selectedEntity?.id;

      const popup = isSelected &&
          <My.Tooltip value={value} country={country}/>;

      return (
        <My.Marker
            isSelected={isSelected}
            key={id}
            style={{
              opacity: isSelected ? 1.0 : (!!selectedEntity ? 0.4 : 1.0),
              zIndex: isSelected ? 199 : 98
            }}
            items={[item]}
            longitude={longitude}
            latitude={latitude}
            onClose={deselect}
            onClick={() => { toggleSelectedEntity(item.properties); }}
            popup={popup}
            zoom={mapState.zoom}
          >
            <My.ValueMarker 
              isSelected={isSelected}
              size={markerSize} 
              value={value}
            />
          </My.Marker>
      );
    });
  }, [items, zoom]);

  return (
    <MapBoxRefProvider value={mapRef}>
      <CoreEntityMapPanel 
        className={cls.name(className)}
        items={items}
        onLoad={onMapLoad}
      >
        {markers}
        <My.MapLegends />
      </CoreEntityMapPanel>
    </MapBoxRefProvider>
  )
};

EntityMapPanel.cls = cls;
EntityMapPanel.defaultProps = EntityMapPanelDefaultProps;