import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, MappableEntity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EntityBubbleImage } from '@shapeable/ui';
const cls = classNames('entity-map-marker');

// -------- Types -------->


export type ValueMarkerProps = Classable & HasChildren & { 
  entity?: any;
  size: number;
  value?: number;
  pinSize?: number;
  isSelected?: boolean;
  imageStyle?: string;
  onClick?: React.MouseEventHandler;
};

export const ValueMarkerDefaultProps: Omit<ValueMarkerProps, 'entity'> = {
  size: 15,
  pinSize: 15,
};

type ContainerProps = {
  size: number;
};

type ValueProps = {
  fontSize: number;
};


// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #FFF;
    border-radius: 100%;
    background: rgba(0, 128, 0, 0.8);
    border: 1px solid ${theme.COLOR('bright')};

    ${({ size }: ValueMarkerProps) => css`
      width: ${size}px;
      height: ${size}px;
    `}
  `,
});

const ValueStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('bright')};
    ${({ fontSize }: ValueProps ) => css`
      font-size: ${fontSize}px;
    `}
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Value: styled.span<ValueProps>`${ValueStyles}`,
};

export const ValueMarker: Shapeable.FC<ValueMarkerProps> = (props) => {
  const { className, entity, value, size, pinSize } = props;
  
  const fontSize = size * 0.4;

  return (
    <My.Container className={cls.name(className)} size={size}>
      <My.Value fontSize={fontSize}>{value}</My.Value>
    </My.Container>
  )
};

ValueMarker.defaultProps = ValueMarkerDefaultProps;
ValueMarker.cls = cls;