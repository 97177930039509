import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
const cls = classNames('page-blockquote');
import { get } from 'lodash';
import { classNames } from '@shapeable/utils';
import { useEntity } from '@shapeable/ui';
import { Event } from '@shapeable/gesda-types';

// -------- Types -------->

export type EventBlockquoteProps = Classable & HasChildren & { 
  entity?: Event;
};

export const EventBlockquoteDefaultProps: Omit<EventBlockquoteProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
  _color: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5)} ${theme.UNIT(5)} ${theme.UNIT(5,2)};
    font-family: ${theme.FONT('serif')};
    font-weight: 500;
    font-size: 1.2em;
    border-radius: 0 0 ${theme.UNIT(3)} ${theme.UNIT(3)};
    line-height: 1.5em;
    margin-bottom: ${theme.UNIT(6)};
    background-color: ${theme.COLOR('brightWarm')};
    color: ${theme.COLOR('text')};
    ${({ _color }: ContainerProps ) => css`
      border-top: 4px solid ${theme.COLOR(_color)};
    `}
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
};

export const EventBlockquote: React.FC<EventBlockquoteProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  const color = get(entity, 'trend.color.value', 'primary');

  return (
    <My.Container _color={color} className={cls.name(className)}>
    &ldquo;{children}&rdquo;
    </My.Container>
  )
};

EventBlockquote.defaultProps = EventBlockquoteDefaultProps;