import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { IconTextValue, useLang } from '@shapeable/ui';
import { AiLowerCaseIconGlyph } from '@shapeable/icons';
const cls = classNames('explorer-gpt--banner-layout');

// -------- Types -------->

// -------- Props -------->

export type ExplorerGptBannerLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerGptBannerLayoutDefaultProps: Omit<ExplorerGptBannerLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const BannerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5)} ${theme.UNIT(4)};
    border-top: 3px solid ${theme.COLOR('line-subtle')};
    border-radius: 0px 0px 8px 8px;
    background: ${theme.COLOR('brightWarm')};
  `,
});

const IconHeaderStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(32)};
    padding-bottom: ${theme.UNIT(2)};
  `,
});


// -------- Components -------->

const My = {
  Banner: styled.div<ContainerProps>`${BannerStyles}`,
  IconHeader: styled(IconTextValue)`${IconHeaderStyles}`,
};

export const ExplorerGptBannerLayout: Shapeable.FC<ExplorerGptBannerLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const t = useLang();
  
  return (
    <My.Banner>
      <My.IconHeader color='strong' iconSize={55} reverse={true} iconComponent={AiLowerCaseIconGlyph}>{t('Radar')}</My.IconHeader>
    </My.Banner>
  )
};

ExplorerGptBannerLayout.defaultProps = ExplorerGptBannerLayoutDefaultProps;
ExplorerGptBannerLayout.cls = cls;