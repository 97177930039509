import React from 'react';
import styled, { css } from 'styled-components';
import { Shapeable } from '@shapeable/types';
import { breakpoints } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EntityCardProps, EntityCardDefaultProps, EntityCard, EntityStack, EntityColorLabel } from '@shapeable/ui';
import { Event } from '@shapeable/gesda-types';
import { isOpportunityEvent } from '../../types';
const cls = classNames('event-card');

// -------- Types -------->

// -------- Props -------->

export type EventCardProps = Omit<EntityCardProps, 'entity'> & { 
  entity?: Event;
};

export const EventCardDefaultProps: Omit<EventCardProps, 'entity'> = {
  ...EntityCardDefaultProps,
  descriptionMaxLines: 4,

};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
s
  `,
});

// -------- Components -------->

const My = {
  Container: styled(EntityCard)<ContainerProps>`${ContainerStyles}`,
};

export const EventCard: Shapeable.FC<EventCardProps> = (props) => {
  const { className, entity, ...cardProps } = props;

  const { sessions = [], roles = [] } = entity;

  const people = isOpportunityEvent(entity)
  ? roles.flatMap(role => role?.person)
  : sessions.reduce((acc, session) => acc.concat(session?.presenters), []);

  const { trend } = entity;
  
  return (
    <My.Container 
      {...cardProps}
      className={cls.name(className)}
      entity={entity}
      showDescription={false}
      showColorStrip
      colorStripColor={trend?.color?.value}
      meta={
        entity.trend && 
        <EntityColorLabel entity={entity?.trend} />
      }
      foot={
        people.length !== 0 &&
        <EntityStack items={people} />
      }
    />
  )
};

EventCard.defaultProps = EventCardDefaultProps;
EventCard.cls = cls;