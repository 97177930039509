import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, EntityWithCoordinates } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { MarkerProps, Marker } from 'react-map-gl';
import { MapPopup } from '@shapeable/maps';
const cls = classNames('map-value-marker');

// -------- Types -------->

// -------- Props -------->

export type MapMarkerProps = Classable & HasChildren & Omit<MarkerProps, 'popup' | 'isSelected' | 'onClick'> & { 
  zoom: number;
  items?: any[];
  onClick?: React.MouseEventHandler;
  onClose?: React.MouseEventHandler;
  maxSize?: number;
  usePinIcon?: boolean;
  scaleFactor?: number;
  isSelected?: boolean;
  popup?: React.ReactNode;
};

export const MapMarkerDefaultProps: Omit<MapMarkerProps, 'zoom' | 'latitude' | 'longitude'> = {
  scaleFactor: 10,
  maxSize: 40,
  items: [],
  anchor: 'bottom',
};

// -------- Child Component Props -------->

type BodyProps = {

};

// -------- Styles -------->

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${theme.COLOR('text')};
    position: relative;
  `,
});


const PopupStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
    z-index: 300;
  `,
});

const ButtonStyles = breakpoints({
  base: css`
    ${theme.REMOVE_BUTTON_STYLE()};
  `,
});


// -------- Components -------->

const My = {
  Body: styled.div<BodyProps>`${BodyStyles}`,
    MarkerButton: styled.button`${ButtonStyles}`,
    Popup: styled(MapPopup)`${PopupStyles}`,

};

export const MapMarker: Shapeable.FC<MapMarkerProps> = (props) => {
  const { className, zoom, maxSize, scaleFactor, children, isSelected, items, onClick, onClose, popup, ...rest } = props;
  
  return (
    <Marker
      {...rest}
      anchor="bottom">
        <My.Body>
        {
          popup && <My.Popup className={className} onClose={onClose}>{popup}</My.Popup>
        }
        <My.MarkerButton onClick={onClick}>
          {children}
        </My.MarkerButton>
      </My.Body>
    </Marker>
  )
};

MapMarker.defaultProps = MapMarkerDefaultProps;
MapMarker.cls = cls;