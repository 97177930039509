import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { FeedEntryTypesProvider as Provider } from '../../components/context/feed-entry-types-context';
import { sortBy } from 'lodash';

const QUERY = graphql`
  query FeedEntryTypesQuery {
    platform {
      allFeedEntryTypes {
        id name slug
      }
    }
  }
`;

export const FeedEntryTypesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allFeedEntryTypes' ) }>{children}</Provider>;
};