import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { SiteHeaderLayout, SiteHeaderProvider } from '@shapeable/ui';
import { ExplorerIndexLayout } from '../explorer/explorer-index-layout';
import { classNames } from '@shapeable/utils';
const cls = classNames('page-layout-Home');

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  `,
});

const LayoutStyles = breakpoints({
  base: css`
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const FooterStyles = breakpoints({
  base: css`
  `
});


// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Layout: styled(ExplorerIndexLayout)`${LayoutStyles}`,
};

export const PageLayoutHome: PageLayoutComponent = (props) => {
  const { className, entity } = props;

  const ref = React.useRef<HTMLDivElement>(null);
  // console.log(ref);

  return (
    <SiteHeaderProvider
      value={{
        variant: 'default'
      }}>
      <My.Container className={cls.name(className)}>
        <My.Header ref={ref} />
        <My.Layout entity={entity} />
      </My.Container>
    </SiteHeaderProvider>
  )
};
