import React from 'react';
import { LinearNavigationProvider as CoreProvider, LinearNavigationItem, useTrends } from "@shapeable/ui"

import { compact, sortBy } from 'lodash';
import { Trend } from '@shapeable/gesda-types';

export const LinearNavigationProvider: React.FC<any> = ({ children }) => {

  const trends = useTrends() as Trend[];
  const items: LinearNavigationItem[] = [];

  for (const trend of trends) {
    items.push({
      id: trend.id,
      name: compact([trend.outlineNumber, trend.name]).join(" - "),
      depth: 0,
      path: trend.path,
      color: trend.color,
      slug: trend.slug,
    });

    for (const topic of sortBy(trend.topics, 'outlineNumber')) {
      items.push({
        id: topic.id,
        name: compact([topic.outlineNumber, topic.name]).join(" - "),
        depth: 1,
        path: topic.path,
        slug: topic.slug,
        _schema: topic._schema,
      });

      for (const subTopic of sortBy(topic.subTopics, 'outlineNumber')) {
        items.push({
          id: subTopic.id,
          name: compact([subTopic.outlineNumber, subTopic.name]).join(" - "),
          depth: 2,
          path: subTopic.path,
          slug: subTopic.slug,
          _schema: subTopic._schema,
        });
      }
    }
  }

  return (
    <CoreProvider value={items}>
    {children}
    </CoreProvider>
  )
}