import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { TrendsExplorerProvider as Provider } from '../../components/context/trends-explorer-context';

const QUERY = graphql`
  query TrendsExplorerQuery {
    platform {
      allTrends { 
        id name path outlineNumber slug __typename
        color { id name value }
        openGraph {
          title date
          image { url url2x thumbnails { bubble { url url2x } card { url url2x } } }
        }
        topics {
          id name path outlineNumber slug __typename
          color { id name value veryLightValue }
          people {
            name
            openGraph {
              title date description { plain }
              image { url url2x thumbnails { small { url url2x } } }
            }
          }
          openGraph {
            title date
            image { url url2x thumbnails { card { url url2x } } }
          }
          subTopics {
            id name path outlineNumber slug __typename
            color { id name value }
            openGraph {
              title date
              image { url url2x thumbnails { card { url url2x } } }
            }
            people {
              name
              openGraph {
                title date description { plain }
                image { url url2x thumbnails { small { url url2x } } }
              }
            }
          }
        }
      }
    }
  }
`;

export const TrendsExplorerProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allTrends' ) }>{children}</Provider>;
};