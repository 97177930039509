import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, OutlineEntity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EntityFilters, SearchBar, useSubTopics, useTopics, useTrends, useSearch, useView, NoResultsLayout, EntityGrid, ExplorerEntityGridLayout } from '@shapeable/ui';
import { ExplorerView } from '../../types';
import { sortBy, each } from 'lodash';
import { useInvitedContributions } from '../../hooks/use-invited-contributions';
import { usePulseOfScienceFilters } from '../../hooks/use-pulse-of-science-filters';
import { SiteFooterLayout } from '@shapeable/ui';
import { useLenses } from '../../hooks/use-lenses';
import { useDeepDives } from '../../hooks/use-deep-dives';
const cls = classNames('radar-explorer-layout');

// -------- Types -------->

export type ExplorerPulseOfScienceLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerPulseOfScienceLayoutDefaultProps: Omit<ExplorerPulseOfScienceLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    .shp--sub-topic-card .shp--entity-card__container {
      background: ${theme.COLOR('bright')};
    }
  `,
});

const FiltersStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const ExplorerPulseOfScienceLayout: Shapeable.FC<ExplorerPulseOfScienceLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const allTrends = useTrends();
  const allTopics = useTopics();
  const allSubTopics = useSubTopics();
  const allInvitedContributions = useInvitedContributions();
  const allLenses = useLenses();
  const allDeepDives = useDeepDives();
;
  const view = 'pulse-of-science';
  const { filters, resultsFor, types } = useView<ExplorerView>(view);

  const { trends = [], topics = [], subTopics = [], editions = [] } = filters;

  const allSelectedSlugs = [...trends, ...topics, ...subTopics];

  const { selects, typeToggles } = usePulseOfScienceFilters();

  const filterItem = (item: OutlineEntity) => {
    if (allSelectedSlugs.length === 0) return true;
    return allSelectedSlugs.includes(item.slug);
  };
  
  const filteredTrends = allTrends?.filter(filterItem);
  const filteredTopics = allTopics?.filter(filterItem);
  const filteredSubTopics = allSubTopics?.filter(filterItem);
  const filteredInvitedContributions = allInvitedContributions?.filter(page => editions.length == 0 || editions.includes(page.badge));

  const toggleItems = [
    ...(resultsFor({ type: 'Trend', data: filteredTrends, by: 'name' })), 
    ...(resultsFor({ type: 'Topic', data: filteredTopics, by: 'name' })), 
    ...(resultsFor({ type: 'SubTopic', data: filteredSubTopics, by: 'name' })), 
    ...(resultsFor({ type: 'InvitedContribution', data: filteredInvitedContributions, by: 'name' })),
    ...(resultsFor({ type: 'Lens', data: allLenses, by: 'name' })),
    ...(resultsFor({ type: 'DeepDive', data: allDeepDives, by: 'name' })),
  ];

  const gridItems = sortBy(toggleItems, 'outlineNumber');

  return (
    <My.Container 
      className={cls.name(className)}
      items={gridItems}
      showSearchBar
      anyCardProps={{ showDescription: false }}
      filters={
        <My.Filters 
          view={view} 
          selects={selects} 
          typeToggles={typeToggles}
        />
      } 
    />
  )
};

ExplorerPulseOfScienceLayout.defaultProps = ExplorerPulseOfScienceLayoutDefaultProps;
ExplorerPulseOfScienceLayout.cls = cls;