import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { EntityTypeProvider as Provider } from '../../components/context/entity-type-context';

const ENTITY_TYPES_QUERY = graphql`
  query EntityTypesQuery {
    platform {
      allEntityTypes {
        id name slug path __typename _schema { label pluralLabel }
      }
    }
  }
`;

export const EntityTypesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(ENTITY_TYPES_QUERY), 'allEntityTypes' ) }>{children}</Provider>;
}