import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { RadarToggleButton } from './radar-toggle-button';
import AnimateHeight from 'react-animate-height';
import { useLang } from '@shapeable/ui';
import { RadarChart } from '@shapeable/gesda-ui';
const cls = classNames('radar-main');

// -------- Types -------->

// -------- Props -------->

export type RadarChartMainProps = Classable & HasChildren & {
}

export const RadarChartMainDefaultProps: RadarChartMainProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${theme.COLOR('line-mid')};
  `,
});

const ToggleButtonStyles = breakpoints({
  base: css`
  `,
});

const RadarContainerStyles = breakpoints({
  base: css`
    padding-right: ${theme.UNIT(8)};
    background: ${theme.COLOR('bright-warm')};
    position: relative;
  `,
});


const RadarStyles = breakpoints({
  base: css`
    margin-top: -${theme.UNIT(2)};
    background: ${theme.COLOR('bright-warm')};
  `,
});

const ScrollLineStyles = breakpoints({
  base: css`
    border-left: 1px dashed ${theme.COLOR('line-mid')};
    height: calc(100% - 32px);
    width: 2px;
    position: absolute;
    top: 16px;
    right: 20px;
  `,
});

const ScrollLabelStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(12)};
    text-transform: uppercase;
    border-radius: ${theme.UNIT(3)};
    color: ${theme.COLOR('subtle')};
    position: absolute;
    right: -28px;
    top: calc(50% - ${theme.UNIT(6)});
    z-index: 100;
    line-height: 1.0em;
    background: ${theme.COLOR('bright-warm')};
    padding: ${theme.UNIT(0)} ${theme.UNIT(2)};
    transform: rotate(-90deg);
    transform-origin: center center;
  `,
});






// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    ToggleButton: styled(RadarToggleButton)`${ToggleButtonStyles}`,
    RadarContainer: styled.div`${RadarContainerStyles}`,
      ScrollLine: styled.div`${ScrollLineStyles}`,
      ScrollLabel: styled.span`${ScrollLabelStyles}`,
      Radar: styled(RadarChart)`${RadarStyles}`,
};

export const RadarChartMain: Shapeable.FC<RadarChartMainProps> = (props) => {
  const { className } = props;
  const [ isVisible, setIsVisible ] = React.useState(false);
  const t = useLang();

  return (
    <My.Container className={cls.name(className)}>
      <My.ToggleButton isExpanded={isVisible} onClick={() => { setIsVisible(!isVisible) }} />
        <AnimateHeight duration={300} height={isVisible ? 'auto' : 0}>
        <My.RadarContainer>
          <My.ScrollLine />
          <My.ScrollLabel>{t('Scroll Page')}</My.ScrollLabel>
          <My.Radar initialY={12} fadeSize={8} bottomFadeSize={0} />
        </My.RadarContainer>
      </AnimateHeight>
    </My.Container>
  )
};

RadarChartMain.cls = cls;
RadarChartMain.defaultProps = RadarChartMainDefaultProps;