import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { MapLegendReveal } from './map-legend-reveal';
import { MapLegend } from './map-legend';
import { ValueMarker } from './value-marker';
const cls = classNames('map-legends');

// -------- Types -------->

// -------- Props -------->

export type MapLegendsProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const MapLegendsDefaultProps: Omit<MapLegendsProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: ${theme.UNIT(2)};
  `,
  tablet: css`
    gap: ${theme.UNIT(3)};
  `,
});

const LegendRevealStyles = breakpoints({
  base: css`
    
  `,
});

const LegendCountriesStyles = breakpoints({
  base: css`
    
  `,
});

const LegendSurveryValueStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  LegendReveal: styled(MapLegendReveal)`${LegendRevealStyles}`,
  LegendCountries: styled(MapLegend)`${LegendCountriesStyles}`,
  LegendSurveyValue: styled(MapLegend)`${LegendSurveryValueStyles}`,
};

export const MapLegends: Shapeable.FC<MapLegendsProps> = (props) => {
  const { className, children, entity } = props;
  
  return (
    <My.Container className={cls.name(className)}>
      <My.LegendReveal />
      <My.LegendCountries title="Contributor Countries" showImage={false} color='rgba(27, 177, 171, 0.7)' />
      <My.LegendSurveyValue title="Contributor Count" showImage={false} legendKeyComponent={<ValueMarker size={26} value={12} />} />
    </My.Container>
  )
};

MapLegends.defaultProps = MapLegendsDefaultProps;
MapLegends.cls = cls;