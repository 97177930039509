import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, entityLabel } from '@shapeable/utils';
import { flatten, sortBy, each } from 'lodash';
import { Event, Page, Topic } from '@shapeable/gesda-types';
import { CellLabel, EntityMetaSection, MetaContainer, useLang } from '@shapeable/ui';
import { sortConnectedTrends } from '../../utils/sort-connected-trends';
const cls = classNames('page-meta-head-layout');

// -------- Types -------->


// -------- Props -------->

export type PageMetaLayoutProps = Classable & HasChildren & { 
  entity?: Page;
};

export const PageMetaLayoutDefaultProps: Omit<PageMetaLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
    gap: ${theme.UNIT(4)};
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(6)};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-size: 1em;
    color: ${theme.COLOR('text-mid')};
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const MetaSectionStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled(MetaContainer)<ContainerProps>`${ContainerStyles}`,
    Section: styled.section`${SectionStyles}`,
      Title: styled(CellLabel)`${TitleStyles}`,
      MetaSection: styled(EntityMetaSection)`${MetaSectionStyles}`,
};

export const PageMetaLayout: Shapeable.FC<PageMetaLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { people = [], topics = [], authors = [], connectedTrends = [], connectedTopics = [],
    connectedSubTopics = [], connectedPages = [], events = [], type, types = [] } = entity;

  const allConnectedTrends = [...connectedTrends, ...connectedTopics, ...connectedSubTopics];
  const sortedConnectedTrends = sortConnectedTrends(allConnectedTrends);

  const hasPeople = !!people.length;
  const hasAuthors = !!authors.length;
  const hasConnectedTrends = !!allConnectedTrends.length;
  const hasTopics = !!topics.length;
  const hasOpportunities = !!events.length;
  const hasConnections = hasConnectedTrends || hasOpportunities;

  const entityTypeName = entityLabel(entity);

  // need to handle this better
  const hasPageType = !!(type?.name || types.length);

  const t = useLang();

  return (
    <My.Container className={cls.name(className)}>
    {/* {
      hasPageType && (
        <>
          {
            hasPeople &&
            <My.Section>
              <My.Title>{t('People')}</My.Title>
              <My.MetaSection items={people} showAllItems={false} />
            </My.Section>
          }
        </>
      )
    } */}
    {
      entityTypeName === 'Deep Dive' && (
        <>
          {
            hasTopics &&
            <My.Section>
              <My.MetaSection label="Scientific Moderators" items={flatten(topics?.map((topic: Topic) => topic?.moderators || []))} />
            </My.Section>
          }
          {
            hasPeople &&
            <My.Section>
              <My.MetaSection label="Contributors" items={people} showAllItems={false} />
            </My.Section>
          }
          {
            hasConnections &&
            <My.Section>
              <My.Title>{t('Connections')}</My.Title>
              {
                hasConnectedTrends && (
                  <My.MetaSection label="Pulse of Science" items={sortedConnectedTrends || []} />
                )
              }
              {
                hasOpportunities && (
                  <My.MetaSection label="Pulse of Diplomacy" items={events || []} />
                )
              }
            </My.Section>
          }
        </>
      )
    }
    {
      entityTypeName === 'Invited Contribution' && (
        <>
          {
            hasAuthors &&
            <My.Section>
              <My.MetaSection label="Authors" items={flatten(authors || [])} />
            </My.Section>
          }
          {
            hasConnectedTrends &&
            <My.Section>
              <My.Title>{t('Connections')}</My.Title>
              <My.MetaSection label="Pulse of Science" items={sortedConnectedTrends || []} />
            </My.Section>
          }
        </>
      )
    }
    {
      entityTypeName === 'Lens' && (
        <>
          {
            hasTopics &&
            <My.Section>
              <My.MetaSection label="Scientific Moderators" items={flatten(topics?.map((topic: Topic) => topic?.moderators || []))} />
            </My.Section>
          }
          {
            hasPeople &&
            <My.Section>
              <My.MetaSection label="Contributors" items={people} showAllItems={false} />
            </My.Section>
          }
          {
            hasConnections &&
            <My.Section>
              <My.Title>{t('Connections')}</My.Title>
              {
                hasConnectedTrends && (
                  <My.MetaSection label="Pulse of Science" items={sortedConnectedTrends || []} />
                )
              }
              {
                hasOpportunities && (
                  <My.MetaSection label="Pulse of Diplomacy" items={events || []} />
                )
              }
            </My.Section>
          }
        </>
      )
    }
    </My.Container>
  )
};

PageMetaLayout.defaultProps = PageMetaLayoutDefaultProps;
PageMetaLayout.cls = cls;