import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, SubTopicsProvider as Provider } from '@shapeable/ui';

const SUB_TOPICS_QUERY = graphql`
  query SubTopicsQuery {
    platform {
      allSubTopics {
        id path slug name outlineNumber __typename
        typeLabel
        openGraph { description { plain } title id image { id url url2x thumbnails { card { id url url2x } small { id url url2x } } } }
        color { id name value veryLightValue }
        _schema { label pluralLabel }
        people {
          name id path slug __typename
          openGraph {
            id title date description { id plain }
            id image { url url2x thumbnails { small { url url2x } } }
          }
        }
      }
    }
  }
`;

export const SubTopicsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(SUB_TOPICS_QUERY), 'allSubTopics' ) }>{children}</Provider>;
};
