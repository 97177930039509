import { Event, Page, Person, SubTopic, Topic, Trend } from "@shapeable/gesda-types";
import { isDebatePage, isDeepDivePage, isInvitedContribution, isWorkshopEvent, isOpportunityEvent, isLensPage } from "../types";
import { Link } from "@shapeable/types";

// patches page paths to be a view / sub-view hash state, based on regular expression search on what would be the standard path
// this relies on diligence from the CMS authors to ensure the pages are named according to those fragments 
// which may be slightly fragile, but is a way to get the desired behavior

// const patchSubView: (page: Page, view: string, paths: string[]) => Page = (page, view, pathFragments) => {

//   if (page.trend?.path) {
//     const matchingSubView = pathFragments.find(fragment => page.path?.match(new RegExp(fragment, "i")));

//     if (matchingSubView) {
//       return {

//         ...page,
//         path: `${page.trend.path}#view=${view};subview=${matchingSubView}`
//       }
//     }
//   }

//   return page;
// }

export const patchTrendSocietyView: (trend: Trend, view: string) => Trend = (trend, view) => {

  if (trend?.path) {

      return {
        ...trend,
        path: `${trend.path}#view=${view};`,
        typeLabel: 'Pulse Of Society',
        __typename: 'Debate',
      }
  }

  return trend;
};

export const patchTrendView: (trend: Trend, view: string) => Trend = (trend, view) => {

  if (trend?.path) {

      return {
        ...trend,
        path: `${trend.path}#view=${view};`
      }
  }

  return trend;
};

export const transformPage: (entity: Page) => Page = (entity) => {
  let transformedPage = { ...entity };

  if (isDebatePage(transformedPage)) {
    // @ts-ignore
    transformedPage.__typename = 'Debate';
    transformedPage.typeLabel = 'Pulse Of Society';
    transformedPage._schema = { label: 'Pulse Of Society', pluralLabel: 'Debates' };

    // also change the paths of these pages to be a hash state
    // transformedPage = patchSubView(transformedPage, 'society', ['what-do-people-do', 'what-do-people-say', 'media-analysis']);
  }

  if (isDeepDivePage(transformedPage)) {
    // @ts-ignore
    transformedPage.__typename = 'DeepDive';
    transformedPage.typeLabel = 'Deep Dive';
    transformedPage._schema = { label: 'Deep Dive', pluralLabel: 'Deep Dives' };
  }

  if (isInvitedContribution(transformedPage)) {
    // @ts-ignore
    transformedPage.__typename = 'InvitedContribution';
    transformedPage._schema = { label: 'Invited Contribution', pluralLabel: 'Invited Contributions' };
  }

  if (isLensPage(transformedPage)) {
    // @ts-ignore
    transformedPage.__typename = 'Lens';
    transformedPage.typeLabel = 'Lens';
    transformedPage._schema = { label: 'Lens', pluralLabel: 'Lenses' };
  }

  // also pull the color from related trend, so that we can show color strips
  transformedPage.color = transformedPage.trend?.color;

  // transform connected pages too

  if (transformedPage.connectedPages) {
    transformedPage.connectedPages = transformedPage.connectedPages.map(transformPage);
  }
  
  return transformedPage;
};

export const transformEvent: (entity: Event) => Event = (entity) => {
  let transformedEvent = { ...entity };

  if (isWorkshopEvent(transformedEvent)) {
    // @ts-ignore
    transformedEvent.__typename = 'Workshop';
    transformedEvent.typeLabel = 'Workshop';
    transformedEvent._schema = { label: 'Workshop', pluralLabel: 'Workshops' };
  }

  if (isOpportunityEvent(transformedEvent)) {
    // @ts-ignore
    transformedEvent.__typename = 'Opportunity';
    transformedEvent.typeLabel = 'Opportunity';
    transformedEvent._schema = { label: 'Opportunity', pluralLabel: 'Opportunities' };
  }

  return transformedEvent;

};


export const transformPeopleEvents: (entity: Person) => Person = (entity) => {
  const transformed = { 
    ...entity,
    relatedEvents: [
      ...(entity.relatedEvents || []).map(transformEvent)
    ],
  };
  
  return transformed;
};


export const transformTrend: (entity: Trend) => Trend = (entity) => {
  const transformed = { 
    ...entity,
    pages: [
      ...(entity.pages || []).map(transformPage).map(entity => ({
        ...entity,
        color: entity.color
      }))
    ],
    events: [
      ...(entity.events || []).map(transformEvent)
    ],

  };
  
  return transformed;
};

export const transformTopic: (entity: Trend) => Trend = (entity) => {
  const transformed = { 
    ...entity,
    pages: [
      ...(entity.pages || []).map(transformPage).map(entity => ({
        ...entity,
        color: entity.color
      }))
    ],
  };
  
  if (transformed.connectedPages) {
    transformed.connectedPages = transformed.connectedPages.map(transformPage);
  }
  

  return transformed;
};



export const transformSubTopic: (entity: SubTopic) => SubTopic = (entity) => {
  const transformed = { 
    ...entity,
  };
  
  if (transformed.connectedPages) {
    transformed.connectedPages = transformed.connectedPages.map(transformPage);
  }

  return transformed;
};


// @ts-ignore
export const transformPeople: (entity: Person) => Person = (entity) => {
  return entity;
};

export const transformTopicExperts: (entities: Person[]) => Person[] = (entities) => {
  const transformed = entities.map(transformPeople);
  
  return transformed;
}
