import { compact, uniqBy } from "lodash";
import { useEconomies, useOrganisationTypes, usePeople, useSubTopics, useTopics, useTrends } from "@shapeable/ui";
import { sortBy, reverse } from "lodash";
import { Economy } from "@shapeable/types";
import { Person, Trend, Topic, Edition } from "@shapeable/gesda-types";
import { useEditions } from "./use-editions";

export const useCommunityFilters = () => {
  const trends = useTrends();
  const topics = useTopics();
  const people = usePeople();
  const editions = useEditions();

  const economies = uniqBy(compact(people.map(person => person.country)), 'slug');

  const filterCountries = (people: Person[], countries: Economy[]) => {
    return countries.filter(country => 
      people.some(person => person?.country?.slug === country?.slug)
    )
  };

  // const filterEditions = (people: Person[], editions: Edition[]) => {
  //   return editions.filter(edition => 
  //     people.some(person =>
  //       person?.economies?.some(connectedCountry => connectedCountry?.slug === country.slug
  //     ))
  //   )
  // };

  const filterTopics = (people: Person[], topics: Topic[]) => { 
    return topics.filter(topic => 
      people.some(person =>
        person?.topics?.some(connectedTopic => connectedTopic.slug === topic.slug
      ))
    )
  };

  const filterTrends = (people: Person[], trends: Trend[]) => { 
    return trends.filter(trend => 
      people.some(person =>
        person?.topics?.some(topic => topic?.trend?.slug === trend?.slug
      ))
    )
  };

  const allFilteredCountries = filterCountries(people, economies);
  const allFilteredTopics = filterTopics(people, topics);
  const allFilteredTrends = filterTrends(people, trends);

  return {
    selects: compact([
      {
        name: 'editions',
        items: reverse(sortBy(editions, 'name')),
        pluralLabel: 'Editions',
        
      },
      {
        name: 'trends',
        items: sortBy(allFilteredTrends, 'outlineNumber'),
        pluralLabel: 'Scientific Platforms',
      },
      {
        name: 'topics',
        items: sortBy(allFilteredTopics, 'outlineNumber'),
        pluralLabel: 'Emerging Topics',
      },
      {
        name: 'countries',
        items: sortBy(allFilteredCountries, 'name'),
        pluralLabel: 'Countries',
      },
      
    ]),
  };
};