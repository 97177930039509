import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { TrendsRadarProvider as Provider } from '@shapeable/gesda-ui';

const QUERY = graphql`
  query TrendsRadarQuery {
    platform {
      allTrends { 
        id name path outlineNumber slug __typename
        color { id name value }
        openGraph {
          title date description { plain }
          image { url url2x }
        }
        topics {
          id name path outlineNumber slug __typename
          color { id name value }
          trend { id }
          openGraph {
            title date description { plain }
            image { url url2x }
          }
          indicatorValues {
            indicator { 
              id name slug dataSetId
            }
            value
            numericValue
          }
          subTopics {
            topic { id trend { id } }
            id name outlineNumber slug __typename
            color { id name value }
            openGraph {
              title date description { plain }
              image { url url2x }
            }
          }
        }
      }
    }
  }
`;

export const TrendsRadarProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allTrends' ) }>{children}</Provider>;
};