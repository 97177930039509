import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, entityNormalizedField, linkedMatchesFilter } from '@shapeable/utils';
import { EntityFilters, ExplorerEntityGridLayout, useCitations, useFeedEntries, useSearch, useTopics, useVideos, useView } from '@shapeable/ui';
import { sortBy } from 'lodash';
import { useResourceFilters } from '../../hooks/use-resource-filters';
const cls = classNames('explorer-resources-layout');

// -------- Types -------->

// -------- Props -------->

export type ExplorerResourcesLayoutProps = Classable & HasChildren & { 
};

export const ExplorerResourcesLayoutDefaultProps: Omit<ExplorerResourcesLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});


const FiltersStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const ExplorerResourcesLayout: Shapeable.FC<ExplorerResourcesLayoutProps> = (props) => {
  const { className } = props;

  const citations = useCitations();
  const feedEntries = useFeedEntries();
  const videos = useVideos();

  const view = 'radar';
  const { resultsFor, filters } = useView(view);
  const { trends = [], topics = [], types = [], subTopics = [] } = filters;

  const { selects, typeToggles } = useResourceFilters();

  const filteredCitations = citations?.filter(citation => (
    linkedMatchesFilter(citation.trends, trends) &&
    linkedMatchesFilter(citation.topics, topics) && 
    linkedMatchesFilter(citation?.subTopics, subTopics)
  ));

  const filteredArticles = feedEntries?.filter(feedEntry => (
    linkedMatchesFilter(feedEntry.trends, trends) &&
    linkedMatchesFilter(feedEntry.topics, topics) && 
    linkedMatchesFilter(feedEntry.subTopics, subTopics)
  ));

  const filteredVideos = videos?.filter(video => (
    linkedMatchesFilter(video.trends, trends) &&
    linkedMatchesFilter(video.topics, topics) &&
    linkedMatchesFilter(video.subTopics, subTopics)
  ));

  const toggleItems = [
    ...(resultsFor({ type: 'FeedEntry', data: filteredArticles, by: 'name' })), 
    ...(resultsFor({ type: 'Citation', data: filteredCitations, by: 'name' })), 
    ...(resultsFor({ type: 'Video', data: filteredVideos, by: 'name' })), 
  ];

  const gridItems = sortBy(toggleItems, entityNormalizedField('name'));

  return (
    <My.Container 
      className={cls.name(className)}
      items={gridItems}
      showSearchBar
      filters={
        <My.Filters
          view={view}
          selects={selects}
          typeToggles={typeToggles}
        />
      } 
    />
  )
};

ExplorerResourcesLayout.defaultProps = ExplorerResourcesLayoutDefaultProps;
ExplorerResourcesLayout.cls = cls;