import React from "react";
import { useActiveEntity, useAppState, useEntity, useLink } from "@shapeable/ui";
import { Page, SubTopic, Topic, Trend } from "@shapeable/types";
import { entityTypeNameFor } from "@shapeable/utils";


export type RadarMapState = {
  isShown?: boolean;
};


export const RadarDefaultState: RadarMapState = {
  isShown: false
};

export type RadarMapInfo = {
  state: RadarMapState; // no internal state (yet)
  
  isShown: boolean;
  show: () => void;
  hide: () => void;
  toggle: () => void;
};


export type RadarConfig = {
  defaultState: RadarMapState;
}

export const RadarDefaultConfig: RadarConfig = {
  defaultState: RadarDefaultState,
};

export const useRadarMap: () => RadarMapInfo = () => {
 
  const [ appState, setAppState, patchAppState ] = useAppState();
  const RadarMap = appState.RadarMap || RadarDefaultState; 

  const show = () => patchAppState({ RadarMap: { isShown: true } });
  const hide = () => patchAppState({ RadarMap: { isShown: false } });

  const toggle = () => patchAppState({ RadarMap: { isShown: !RadarMap.isShown } }); 

  return {
    isShown: RadarMap.isShown,
    state: RadarMap,
    toggle,
    show,
    hide,
  }

}