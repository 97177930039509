import React from 'react';
import styled, { css } from 'styled-components';
import { Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EntityCardProps, EntityCardDefaultProps, EntityCard, EntityStack } from '@shapeable/ui';
import { Page } from '@shapeable/gesda-types';
const cls = classNames('page-introduction-card');

// -------- Types -------->

// -------- Props -------->

export type PageIntroductionCardProps = Omit<EntityCardProps, 'entity'> & { 
  entity?: Page;
};

export const PageIntroductionCardDefaultProps: Omit<PageIntroductionCardProps, 'entity'> = {
  ...EntityCardDefaultProps,
  showDescription: false,

};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

// -------- Components -------->

const My = {
  Container: styled(EntityCard)<ContainerProps>`${ContainerStyles}`,
};

export const PageIntroductionCard: Shapeable.FC<PageIntroductionCardProps> = (props) => {
  const { className, entity, ...cardProps } = props;

  return (
    <My.Container 
      {...cardProps}
      className={cls.name(className)}
      entity={entity}
      showTypeHeader={true}
      foot={
        entity.people?.length !== 0 &&
        <EntityStack items={entity.people} />
      }
    />
  )
};

PageIntroductionCard.defaultProps = PageIntroductionCardDefaultProps;
PageIntroductionCard.cls = cls;