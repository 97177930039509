import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { PageExplorerParentLayout } from '../entities/page-explorer-parent-layout';
import { BackButtonEntityProvider, EntityTypeBannersProvider, PAGE_HOME, PageHalfBanner } from '@shapeable/ui';
const cls = classNames('page-layout-parent');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Container: styled(PageExplorerParentLayout)<ContainerProps>`${ContainerStyles}`,
  
};

export const PageLayoutParent: PageLayoutComponent = 
(props) => {
  const { className, entity } = props;
  const backEntity = entity.parent && entity.parent.id !== entity.id ? entity.parent : PAGE_HOME;

  // console.log('PageLayoutParent', backEntity);

  return (
    <BackButtonEntityProvider value={backEntity}>
    <My.Container 
      className={cls.name(className)} 
      entity={entity}
    />
    </BackButtonEntityProvider>
  )
};