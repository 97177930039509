import { compact } from "lodash";
import { useCitations, useFeedEntries, useSubTopics, useTopics, useTrends } from "@shapeable/ui";
import { useFeedEntryTypes } from "./use-feed-entry-types";
import { Entity, EntityType, FeedEntry } from "@shapeable/types";
import { SubTopic, Topic, Trend } from "@shapeable/gesda-types";
import { sortBy, kebabCase } from "lodash";
import { useEntityTypes } from "./use-entity-types";
import { entityTypeNameFor } from "@shapeable/utils";

export const useResourceFilters = () => {
  const trends = useTrends();
  const topics = useTopics();
  const subTopics = useSubTopics();
  const feedEntries = useFeedEntries();

  // Below filters to remove all trends, topics, subTopics, and entityTypes that are not connected to the feedEntries
  const filterConnectedTrends = (entity: FeedEntry[], trends: Trend[]) => {
    return trends.filter(trend => 
      entity.some(feedEntry =>
        feedEntry?.trends?.some(connectedTrend => connectedTrend.slug === trend.slug
      ))
    )
  };

  const filterConnectedTopics = (entity: FeedEntry[], topics: Topic[]) => {
    return topics.filter(topic => 
      entity.some(feedEntry =>
        feedEntry?.topics?.some(connectedTopic => connectedTopic.slug === topic.slug
      ))
    )
  };

  const filterConnectedSubTopics = (entity: FeedEntry[], subTopics: SubTopic[]) => {
    return subTopics.filter(subTopic => 
      entity.some(feedEntry =>
        feedEntry?.subTopics?.some(connectedsubTopic => connectedsubTopic.slug === subTopic.slug
      ))
    )
  };

  const allFilteredTrends = filterConnectedTrends(feedEntries, trends);
  const allFilteredTopics = filterConnectedTopics(feedEntries, topics);
  const allFilteredSubTopics = filterConnectedSubTopics(feedEntries, subTopics);

  return {
    selects: compact([
      {
        name: 'trends',
        items: sortBy(allFilteredTrends, 'outlineNumber'),
        pluralLabel: 'Scientific Platforms',
      },
      {
        name: 'topics',
        items: sortBy(allFilteredTopics, 'outlineNumber'),
        pluralLabel: 'Emerging Topics',
      },
      {
        name: 'subTopics',
        items: sortBy(allFilteredSubTopics, 'outlineNumber'),
        pluralLabel: 'Sub-Fields',
      },
    ]),
    typeToggles: [
      { name: 'FeedEntry', label: 'Articles'},
      { name: 'Citation', label: 'Citations' },
      { name: 'Video', label: 'Videos'},
    ]
  };
};