import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellHeader, CellLabel, useLang } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
import { ChevronDownIconGlyph, ChevronUpIconGlyph } from '@shapeable/icons';
const cls = classNames('map-legend-reveal');

// -------- Types -------->

export type MapLegendRevealProps = Classable & HasChildren & {
  title?: string;
  isVisible?: boolean;
}

export const MapLegendRevealDefaultProps: MapLegendRevealProps = {
  isVisible: true,
};

// -------- Child Component Props -------->

type ContainerProps = {
  
}

type BodyProps = {
  isVisible: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${theme.TRANSITION('opacity')};
    width: 200px;
    padding: ${theme.UNIT(2)} ${theme.UNIT(3)} ${theme.UNIT(2)};
    background-color:${theme.COLOR('bright')};
    color: ${theme.COLOR('strong')};
    opacity: 0.85;
    border-radius: ${theme.UNIT(3)};
    box-shadow: 0px 1px 3px #54768866;
    &:hover {
      opacity: 1;
    }
  `,
  tablet: css`
    padding: ${theme.UNIT(3)} ${theme.UNIT(4)} ${theme.UNIT(3)};
    width: 265px;
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    cursor: pointer;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-weight: 500;
    font-size: 1em;
  `,
  tablet: css`
    font-size: 1.1em;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(2)};
    padding-top: 0;
    margin-top: 0;
    border-top: 2px solid ${theme.COLOR('subtle-mid-grey')};
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    transition: 
      opacity 0.3s ease, 
      visibility 0s linear 0.3s, 
      max-height 0.6s ease-in-out,
      padding-top 0.8s ease-in-out, 
      margin-top 0.8s ease-in-out;

    ${({ isVisible }: BodyProps) => isVisible && css`
      opacity: 1;
      padding-top: ${theme.UNIT(5)};
      margin-top: ${theme.UNIT(2)};
      visibility: visible;
      max-height: 500px;
      transition: 
        opacity 0.3s ease, 
        max-height 0.8s ease-in-out, 
        padding-top 0.8s ease-in-out, 
        margin-top 0.8s ease-in-out;
    `}
  `,
});

const ValueTitlesStyles = breakpoints({
  base: css`
    display: flex;
    padding-bottom: ${theme.UNIT(2)};
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    font-weight: 600;
  `,
});

const ContinentAndValuesStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text-mid')};
  `,
});

const LegendItemStyles = breakpoints({
  base: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
});

const ValuesStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text-mid')};
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Layout: styled.div`${LayoutStyles}`,
      Header: styled.div`${HeaderStyles}`,
        Title: styled(CellLabel)`${TitleStyles}`,

      Body: styled.div<BodyProps>`${BodyStyles}`,
        ValueTitles: styled.div`${ValueTitlesStyles}`,
        ContinentAndValues: styled.div`${ContinentAndValuesStyles}`,
          LegendItem: styled.div`${LegendItemStyles}`,
            Values: styled.span`${ValuesStyles}`,
};

export const MapLegendReveal: Shapeable.FC<MapLegendRevealProps> = (props) => {
  const { className, children, title, isVisible, ...rest } = props;

  const [isRevealed, setIsRevealed] = React.useState<boolean>(false);

  const t = useLang();

  const toggleReveal = () => {
    setIsRevealed(!isRevealed);
  }

  return (
    <My.Container className={cls.name(className)}>
      <My.Layout>
        <My.Header onClick={toggleReveal}>
          <My.Title>{t('Results By Continent')}</My.Title>
          { isRevealed ? <ChevronUpIconGlyph size={15} /> : <ChevronDownIconGlyph size={15} /> }
        </My.Header>
        {
          // hard coded values for testing
          <My.Body isVisible={isRevealed}>
            <My.ValueTitles>
              <My.Title>{t('Continent')}</My.Title>
              <My.Title>{t('Results')}</My.Title>
            </My.ValueTitles>
            <My.ContinentAndValues>
              <My.LegendItem>
                <My.Values>{t('Russia, Asia and Oceania')}</My.Values>
                <My.Values>{t('318')}</My.Values>
              </My.LegendItem>
              <My.LegendItem>
                <My.Values>{t('South America')}</My.Values>
                <My.Values>{t('56')}</My.Values>
              </My.LegendItem>
              <My.LegendItem>
                <My.Values>{t('Europe')}</My.Values>
                <My.Values>{t('772')}</My.Values>
              </My.LegendItem>
              <My.LegendItem>
                <My.Values>{t('Africa & Middle East')}</My.Values>
                <My.Values>{t('111')}</My.Values>
              </My.LegendItem>
              <My.LegendItem>
                <My.Values>{t('North America')}</My.Values>
                <My.Values>{t('621')}</My.Values>
              </My.LegendItem>
              <My.LegendItem>
                <My.Values>{t('Switzerland')}</My.Values>
                <My.Values>{t('222')}</My.Values>
              </My.LegendItem>
            </My.ContinentAndValues>
          </My.Body>
        }
      </My.Layout>
    </My.Container>
  )
};

MapLegendReveal.cls = cls;
MapLegendReveal.defaultProps = MapLegendRevealDefaultProps;