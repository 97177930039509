import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EntityFilters, useView, useTopics, useTrends, ExplorerEntityGridLayout } from '@shapeable/ui';
import { ExplorerView, Opportunity } from '../../types';
import { useOpportunities } from '../../hooks/use-opportunities';
import { linkedMatchesFilter } from '../../utils/linked-matches-filter';
import { Event, Topic, Trend } from '@shapeable/gesda-types';
import { sortBy } from 'lodash';
const cls = classNames('radar-opportunities-layout');

// -------- Types -------->

export type ExplorerPulseOfDiplomacyLayoutProps = Classable & HasChildren & { 
  entity?: Opportunity;
};

export const ExplorerPulseOfDiplomacyLayoutDefaultProps: Omit<ExplorerPulseOfDiplomacyLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const FiltersStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled(ExplorerEntityGridLayout)<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const ExplorerPulseOfDiplomacyLayout: Shapeable.FC<ExplorerPulseOfDiplomacyLayoutProps> = (props) => {
  const { className } = props;
  const opportunities = useOpportunities();
  const allTrends = useTrends();
  const allTopics = useTopics();
  const view = 'explorer';

  const { filters: filterSelections } = useView<ExplorerView>(view);
  const { trends = [], topics = [] } = filterSelections;

  const filterEntityByConnectedTopics = (entity: Event[], topics: Topic[]) => {
    return sortBy(topics.filter(topic => 
      entity.some(event =>
        event?.topics.some(eventTopic => eventTopic.slug === topic.slug
      ))
    ), 'outlineNumber' );
  }

  const filterEntityByConnectedTrends = (entity: Event[], trends: Trend[]) => {
    return sortBy(trends.filter(trend => 
      entity.some(event =>
        event?.trends.some(eventTrend => eventTrend.slug === trend.slug
      ))
    ), 'outlineNumber' );
  }

  const allFilteredTopics = filterEntityByConnectedTopics(opportunities, allTopics);
  const allFilteredTrends = filterEntityByConnectedTrends(opportunities, allTrends);

  const selects = [
    {
      name: 'trends',
      items: allFilteredTrends,
      pluralLabel: 'Scientific Platforms',
    },
    {
      name: 'topics',
      items: allFilteredTopics,
      pluralLabel: 'Emerging Topics',
    },
  ];

  const gridItems = opportunities?.filter(opportunity => (
    linkedMatchesFilter(opportunity.trends, trends) &&
    linkedMatchesFilter(opportunity.topics, topics)
  ));

  return (
    <My.Container 
      view={view}
      className={cls.name(className)}
      items={gridItems}
      filters={
        <My.Filters view={view} selects={selects} />
      } 
    />
  );
};

ExplorerPulseOfDiplomacyLayout.defaultProps = ExplorerPulseOfDiplomacyLayoutDefaultProps;
ExplorerPulseOfDiplomacyLayout.cls = cls;