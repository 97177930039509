import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
const cls = classNames('radar-toggle-button');
import RADAR_ICON from '../../images/radar-icon.png';
import { useLang } from '@shapeable/ui';
import { ChevronDownIcon, ChevronDownIconGlyph } from '@shapeable/icons';

// -------- Types -------->

// -------- Props -------->

export type RadarToggleButtonProps = Classable & HasChildren & {
  isExpanded?: boolean;
  onClick?: () => void;
}

export const RadarToggleButtonDefaultProps: RadarToggleButtonProps = {
  isExpanded: false,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

type IconProps = {
  isExpanded: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${theme.TRANSITION('color')};
    ${theme.REMOVE_BUTTON_STYLE()};
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    font-weight: 400;
    padding: ${theme.UNIT(2)} ${theme.UNIT(3, 2)} ${theme.UNIT(2, 2)};
    font-family: ${theme.FONT('sans')};
    justify-content: space-between;
    font-size: ${theme.FONT_SIZE(13)};
    color: ${theme.COLOR('sub')};
    background: ${theme.COLOR('bright-warm')};
    color: ${theme.COLOR('sub')};

    &:hover {
      color: ${theme.COLOR('strong')};
      ${cls.child('icon')} {
        ${theme.FILL('strong')};
      }
    }

  `,
});

const ImageStyles = breakpoints({
  base: css`
    width: 48px;
    height: 48px;
  `,
});

const LabelStyles = breakpoints({
  base: css`
    text-transform: uppercase;
    color: inherit;
  `,
});

const ToggleLabelStyles = breakpoints({
  base: css`
    text-transform: uppercase;
    color: inherit;
  `,
});

const IconStyles = breakpoints({
  base: css`
    ${theme.TRANSITION('transform')};
    ${theme.FILL('subtle')};
    margin-right: ${theme.UNIT(1)};
    ${({ isExpanded }: IconProps ) => css`
      transform: rotate(${isExpanded ? 180 : 0}deg);
    `}
  `,
});

const StartStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: ${theme.UNIT(3)};
  `,
});

const EndStyles = breakpoints({
  base: css`
    gap: ${theme.UNIT(3)};
    display: flex;
    align-items: center;
    flex-direction: row;
  `,
});






// -------- Components -------->

const My = {
  Container: styled.button<ContainerProps>`${ContainerStyles}`,
    Start: styled.div`${StartStyles}`,
      Label: styled.span`${LabelStyles}`,
      Image: styled.img`${ImageStyles}`,
    
    End: styled.div`${EndStyles}`,
      ToggleLabel: styled.span`${ToggleLabelStyles}`,
      Icon: styled(ChevronDownIconGlyph).attrs(cls.attr('icon'))<IconProps>`${IconStyles}`,
};

export const RadarToggleButton: Shapeable.FC<RadarToggleButtonProps> = (props) => {
  const { className, isExpanded, onClick } = props;
  const t = useLang();

  const toggleLabel = props.isExpanded ? t('Hide') : t('Show');

  const handleClick = onClick && (() => {
    onClick();
  });

  return (
    <My.Container onClick={handleClick} className={cls.name(className)}>
      <My.Start>
      <My.Image src={RADAR_ICON} />
      <My.Label>{t('Radar Visual')}</My.Label>
      </My.Start>

      <My.End>
        <My.Label>{toggleLabel}</My.Label>
        <My.Icon isExpanded={isExpanded} size={16} />
      </My.End>
    </My.Container>
  )
};

RadarToggleButton.cls = cls;
RadarToggleButton.defaultProps = RadarToggleButtonDefaultProps;