import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EntityTooltipProps, useLang } from '@shapeable/ui';
const cls = classNames('survery-value-tooltip');

// -------- Types -------->

// -------- Props -------->

export type SurveryValueTooltipProps = Omit<EntityTooltipProps, 'entity'> & {
  value?: number;
  country?: string;
};

export const SurveryValueTooltipDefaultProps: Omit<SurveryValueTooltipProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    max-width: 250px;
  `,
  tablet: css`
    max-width: 300px;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5)} ${theme.UNIT(8)} ${theme.UNIT(4)} ${theme.UNIT(4)};
    text-align: center;
 `,
  tablet: css`
  `,
});

const LabelStyles = breakpoints({
  base: css`
    font-size: 1em;
  `,
  tablet: css`
    font-size: 1.2em;
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
    Label: styled.span`${LabelStyles}`,
};

export const SurveryValueTooltip: Shapeable.FC<SurveryValueTooltipProps> = (props) => {
  const { className, children, country, value} = props;

  const t = useLang();
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Body>
        <My.Label>
          <strong>{value ?? 0}</strong> {t('global')} {value === 1 ? 'expert' : 'experts'} {t('from')} <strong>{country}</strong> {t('contributed insights on anticipation potential.')}
        </My.Label>
      </My.Body>
    {children}
    </My.Container>
  )
};

SurveryValueTooltip.defaultProps = SurveryValueTooltipDefaultProps;
SurveryValueTooltip.cls = cls;