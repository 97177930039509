import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Image, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
const cls = classNames('legend-key');

// -------- Types -------->

export type LegendKeyProps = Classable & HasChildren & {
  color?: string;
  path?: string;
  size?: number;
  showImage?: boolean;
  legendKeyComponent?: React.ReactNode;
}

export const LegendKeyDefaultProps: LegendKeyProps = {
  size: 20,
  showImage: true,
  color: 'brightWarm',
};

// -------- Child Component Props -------->

type ContainerProps = {
  size?: number;
}

type SquareProps = {
  _color?: string;
}
// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    
  `,
});

const ImageStyles = breakpoints({
  base: css`
    border-radius: 20%;
    border: 2px solid #E8E8E8;
    object-fit: fit;
    width: 16px;
    height: 16px;
  `,
  tablet: css`
    width: 24px;
    height: 24px;
  
  `,
});

const SquareStyles = breakpoints({
  base: css`
    border-radius: 20%;
    border: 2px solid #E8E8E8;
    width: 22px;
    height: 22px;
    

    ${({ _color }: SquareProps ) => css`
      background-color: ${theme.COLOR(_color)};
    `}
  `,
  tablet: css`
    width: 24px;
    height: 24px;
  
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    Image: styled.img<ContainerProps>`${ImageStyles}`,
    Square: styled.div<SquareProps>`${SquareStyles}`,
};

export const LegendKey: Shapeable.FC<LegendKeyProps> = (props) => {
  const { className, size, path, showImage, color, legendKeyComponent } = props;

  const KeyComponent = legendKeyComponent || <My.Square _color={color} />;

  return (
    <My.Container 
      className={cls.name(className)}>
    {
      showImage ? (
        <My.Image src={path} size={size} />
      ) : (
        KeyComponent
      )
    }
    </My.Container>
  )
};

LegendKey.cls = cls;
LegendKey.defaultProps = LegendKeyDefaultProps;