import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { OrganisationsProvider as Provider, platformProviderValue } from '@shapeable/ui';
import { sortBy } from 'lodash';

const ORGANISATIONS_QUERY = graphql`
  query PlatformOrganisationsQuery {
    platform {
      allOrganisations {
        id name slug path url __typename
        typeLabel
        openGraph { description { plain } image { url url2x thumbnails { bubble { url url2x } card { url url2x } } } }
        countries { id slug name __typename }
        logo { url url2x }
        type { id slug name }
      }
    }
  }`;

export const OrganisationsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(ORGANISATIONS_QUERY), 'allOrganisations' ), 'name') }>{children}</Provider>;
}
