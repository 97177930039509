import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, TopicsProvider as Provider } from '@shapeable/ui';

const TOPICS_QUERY = graphql`
  query TopicsQuery {
    platform {
      allTopics {
        id path slug name outlineNumber __typename
        typeLabel
        openGraph { id title description { plain } image { id url url2x thumbnails { bubble { url url2x } card { id url url2x } } } }
        banner { 
          id 
          image { url url2x width height thumbnails { card { url url2x } } } 
          thumbnail { url url2x width height thumbnails { card { url url2x } } }
        }
        color { id name value veryLightValue }
        _schema { label pluralLabel }
        people {
          name id path slug __typename
          openGraph {
            id title date description { plain }
            image { id url url2x thumbnails { small { id url url2x } } }
          }
        }
      }
    }
  }
`;

export const TopicsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(TOPICS_QUERY), 'allTopics' ) }>{children}</Provider>;
};
