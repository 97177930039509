import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, OutlineEntity, PlainObject } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, entityLabel } from '@shapeable/utils';
import { EntityLink, OutlineEntityBubble, OutlineNumberLevel } from '@shapeable/ui';
import { toString } from 'lodash';
const cls = classNames('outline-topic-link-embed');

// -------- Types -------->

export const OutlineNumberLevelNew: PlainObject = {
  '1': 2,
  '2': 1.5,
  '3': 1,
};

export type OutlineEntityLinkEmbedProps = Classable & HasChildren & { 
  entity?: OutlineEntity;
};

export const OutlineEntityLinkEmbedDefaultProps: Omit<OutlineEntityLinkEmbedProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    gap: ${theme.UNIT(3)};
    align-items: center;
    margin: ${theme.UNIT(6)} 0;
    line-height: 1.35em;
    &:hover {
      filter: brightness(1.1);
    }
  `,
});

const OutlineNumberStyles = breakpoints({
  base: css`
    
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const LabelStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(1)};
    text-transform: uppercase;
    font-size: 0.8em;
    color: ${theme.COLOR('text')};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-weight: 500;
    font-size: 1.0em;
  `,
});

// -------- Components -------->

const My = {
  Container: styled(EntityLink)<ContainerProps>`${ContainerStyles}`,
    OutlineNumber: styled(OutlineEntityBubble)`${OutlineNumberStyles}`,

    Body: styled.div`${BodyStyles}`,
    Label: styled.div`${LabelStyles}`,
    Title: styled.b`${TitleStyles}`,
};

export const OutlineEntityLinkEmbed: Shapeable.FC<OutlineEntityLinkEmbedProps> = (props) => {
  const { className, children, entity } = props;
  
  const typeName = entityLabel(entity);

  const level = toString((entity.outlineNumber || '').split('.').length || 1) as OutlineNumberLevel;

  const numberSize = OutlineNumberLevelNew[level] || 1;

  return (
    <My.Container entity={entity} className={cls.name(className)}>
      <My.OutlineNumber entity={entity} numberSize={numberSize} showImage={false} />
      <My.Body>
        <My.Label>{typeName}</My.Label>
        <My.Title>{entity?.name}</My.Title>
      </My.Body>
    </My.Container>
  )
};

OutlineEntityLinkEmbed.defaultProps = OutlineEntityLinkEmbedDefaultProps;
OutlineEntityLinkEmbed.cls = cls;