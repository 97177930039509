import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { PagesProvider as Provider, platformProviderValue } from '@shapeable/ui';
import { sortBy } from 'lodash';

const PAGES_QUERY = graphql`
  query PlatformPagesQuery {
    platform {
      allPages { 
        id path summary { plain } intro { plain } slug name __typename _schema { label pluralLabel } 
        typeLabel
        badge
        trend { id name color { value } }
        openGraph { description { plain } image { url thumbnails { bubble { url url2x } card { url url2x } } } }
        parent { id path slug name }
        types { id name slug }
        people { 
          id name slug 
          openGraph { description { text } image { url thumbnails { card { url url2x } } } }
        }
        connectedTrends {
          id name slug path outlineNumber color { value }
        }
        connectedTopics {
          id name slug path outlineNumber color { value }
        }
      }
    }
  }`;

export const PagesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(PAGES_QUERY), 'allPages' ), 'name') }>{children}</Provider>;
}