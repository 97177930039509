import { compact } from "lodash";
import { useSubTopics, useTopics, useTrends } from "@shapeable/ui";
import { sortBy } from "lodash";
import { entityNormalizedField } from "@shapeable/utils";
import { useEditions } from "./use-editions";

export const usePulseOfScienceFilters = () => {
  const trends = useTrends();
  const topics = useTopics();
  const editions = useEditions();

  return {
    selects: compact([
      {
        name: 'editions',
        items: sortBy(editions, entityNormalizedField('name')),
        pluralLabel: 'Editions',
      },
      {
        name: 'trends',
        items: sortBy(trends, 'outlineNumber'),
        pluralLabel: 'Scientific Platforms',
      },
      {
        name: 'topics',
        items: sortBy(topics, 'outlineNumber'),
        pluralLabel: 'Emerging Topics',
      },
    ]),
    typeToggles: [
      { name: 'Trend', label: 'Scientific Platforms'},
      { name: 'Topic', label: 'Emerging Topics' },
      { name: 'SubTopic', label: 'Sub-Fields'},
      { name: 'InvitedContribution', label: 'Contributions' },
      { name: 'Lens', label: 'Lenses' },
      { name: 'DeepDive', label: 'Deep Dives' },
    ]
  };
};