
import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { applyOpenGraphThumbnailSize, classNames } from '@shapeable/utils';
import { CellLabel, ContentEntityIntro, ContentEntityRichTitle, ContentNode, EntityGrid, GptButton, useLang, useSite } from '@shapeable/ui';
import { Page } from '@shapeable/gesda-types';
import { AiLowerCaseIconGlyph } from '@shapeable/icons';
import { GptButtonSummary } from '../../data';
const cls = classNames('explorer-home-view-layout');

// -------- Types -------->

// -------- Props -------->

export type ExplorerHomeViewLayoutProps = Classable & HasChildren & { 
  entity?: Page;
};

export const ExplorerHomeViewLayoutDefaultProps: Omit<ExplorerHomeViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(3)};
    padding-top: ${theme.UNIT(4)};  
  `,
});

const ContentStyles = breakpoints({
  base: css`

  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(3)};
  `,
});

const TitleAndIntroStyles = breakpoints({
  base: css`
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1.10em;
    line-height: 1.35em;
    font-weight: 400;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-weight: 300;
    margin-bottom: ${theme.UNIT(7)};
    line-height: 1.10;
    font-size: 1.8em;
  `,
});

const ContentLabelStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text')};
    font-weight: 300;
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const GptButtonStyles = breakpoints({
  base: css`
    
  `,
});


const ButtonAndContentStyles = breakpoints({
  base: css`
    display: flex;
    gap: ${theme.UNIT(4)};
    align-items: center;
  `,
});

const PageCardsStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(3)};
    margin-bottom: ${theme.UNIT(3)};
  `,
});

const GptButtonSummaryStyles = breakpoints({
  base: css`
    line-height: 1.35em;
    font-weight: 300;
    font-size: 0.9em;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

    TitleAndIntro: styled.div`${TitleAndIntroStyles}`,
      Title: styled(ContentEntityRichTitle)`${TitleStyles}`,
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,
      PageCards: styled(EntityGrid)`${PageCardsStyles}`,

    Content: styled.div`${ContentStyles}`,
      Section: styled.section`${SectionStyles}`,
        ContentLabel: styled(CellLabel)`${ContentLabelStyles}`,
        ButtonAndContent: styled.div`${ButtonAndContentStyles}`,
          GptButton: styled(GptButton)`${GptButtonStyles}`,
          GptButtonSummary: styled(ContentNode)`${GptButtonSummaryStyles}`,
};

export const ExplorerHomeViewLayout: Shapeable.FC<ExplorerHomeViewLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { cardLinks = [], bannerLinks = [] } = entity;
  
  const applyCardThumbnails = applyOpenGraphThumbnailSize('card');
  const featurePagesTrimmed = cardLinks.slice(0, 4).map(applyCardThumbnails);

  const hasLinkCards = !!featurePagesTrimmed.length;
 
  const t = useLang();
  
  return (
    <My.Container className={cls.name(className)}>
      <My.TitleAndIntro>
        {/* <My.Title entity={entity} /> */}
        <My.Intro entity={entity} />
      </My.TitleAndIntro>
      {
        hasLinkCards && 
        <My.PageCards 
          items={featurePagesTrimmed} 
          spacing={4} 
          minColumns={2}
          tabletAutoColumns={4}
          desktopAutoColumns={4}
        />
      }
      <My.Content>
        <My.Section>
          <My.ContentLabel>{t('Generate personalised results with:')}</My.ContentLabel>
          <My.ButtonAndContent>
            <My.GptButton href='#view=radar-ai' entity={entity} label='Use RadarAI' icon={AiLowerCaseIconGlyph}/>
            <My.GptButtonSummary entity={GptButtonSummary} />
          </My.ButtonAndContent>
        </My.Section>
      </My.Content>
    </My.Container>
  )
};

ExplorerHomeViewLayout.defaultProps = ExplorerHomeViewLayoutDefaultProps;
ExplorerHomeViewLayout.cls = cls;