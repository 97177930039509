import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/ui';
import { CitationsProvider as Provider } from '@shapeable/ui';

const CITATIONS_QUERY = graphql`
  query CitationsQuery {
    platform {
      allCitations {
        id slug url name __typename
        authors { id name slug }
        authorShowsEtAl
        edition
        publication
        accessDate
        startPage
        volume
        footnoteNumber
        year
        topics { id name slug path __typename }
        trends { id name slug path __typename }
      }
    }
  }
`;

export const CitationsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(CITATIONS_QUERY), 'allCitations' ) }>{children}</Provider>;
};