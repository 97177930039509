import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { FeedEntriesProvider as Provider, platformProviderValue } from '@shapeable/ui';
import { sortBy } from 'lodash';

const FEED_ENTRIES_QUERY = graphql`
  query FeedEntriesQuery {
    platform {
      allFeedEntries {
        id path slug name url __typename _schema { label pluralLabel }
        published author 
        typeLabel
        types { id name slug }
        openGraph { description { plain } url title image { url thumbnails { bubble { url url2x } card { url url2x } small { url url2x } } } }
        trends {
          id name slug path outlineNumber color { value }
        }
        topics {
          id name slug path outlineNumber color { value }
        }
        subTopics {
          id name slug path outlineNumber color { value }
        }
        color { 
          id value
        }
      }
    }
  }
`;

export const FeedEntriesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(FEED_ENTRIES_QUERY), 'allFeedEntries' ), 'published') }>{children}</Provider>;
};