import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, entityLabel } from '@shapeable/utils';
import { Page } from '@shapeable/gesda-types';
import { ContentEntityIntro, ContentTitle, EntityGrid, useLang } from '@shapeable/ui';
import { PageIntroductionCard } from './page-introduction-card';
import { includes, sortBy } from 'lodash';
import { useLenses } from '../../hooks/use-lenses';
import { ExplorerProvider } from '../providers/explorer-provider';
import { PageExplorerLayout, PageExplorerLayoutProps } from './page-explorer-layout';
import { useCurrentEditionInvitedContributions } from '../../hooks/use-current-edition-invited-contributions';
import { useCurrentEditionDeepDives } from '../../hooks/use-current-edition-deep-dives';
const cls = classNames('page-explorer-parent-layout');

// -------- Types -------->

// -------- Props -------->

export type PageExplorerParentLayoutProps = Classable & HasChildren & PageExplorerLayoutProps & { 
  entity?: Page;
};

export const PageExplorerParentLayoutDefaultProps: Omit<PageExplorerParentLayoutProps, 'entity'> = {
  isAsideSticky: true,  
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    
  `,
});

const ViewStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
});



const SectionStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(6)} 0;

    &:first-child {
      padding-top: 0;
    }
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: ${18/16}em;
  `,
});

const ChildPagesStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(8)} 0;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-size: ${21/12}em;
  `,
});

const TypePagesStyles = breakpoints({
  base: css`
    ${ChildPagesStyles}
  `,
});

const FooterStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled(PageExplorerLayout)<ContainerProps>`${ContainerStyles}`,
    View: styled.div`${ViewStyles}`,
    Section: styled.section`${SectionStyles}`,
      Intro: styled(ContentEntityIntro)`${IntroStyles}`,
      ChildPages: styled(EntityGrid)`${ChildPagesStyles}`,

      Title: styled(ContentTitle)`${TitleStyles}`,
      TypePages: styled(EntityGrid)`${TypePagesStyles}`,

};

export const PageExplorerParentLayout: Shapeable.FC<PageExplorerParentLayoutProps> = (props) => {
  const { className, entity, ...rest } = props;

  const { children } = entity;

  const t = useLang();

  const invitedContributions = useCurrentEditionInvitedContributions();
  const deepDives = useCurrentEditionDeepDives();
  const lenses = useLenses();

  const firstChildSlugs = (entity.children || []).reduce<String[]>((acc, child) => {
    const slug = (child.family && child.family.length && child.family[0].slug) || child.slug;
    return [ ...acc, slug ];
  }, []);
  
  const items = sortBy(children.filter(child => includes(firstChildSlugs, child.slug)), 'orderNumber');

  // need a way to make this more robust perhaps
  const isEdition = entity?.typeLabel === 'Current Edition';

  return (
  <ExplorerProvider>
    <My.Container
      className={cls.name(className)}
      entity={entity}
      {...rest}
      content={
        <My.View>
        <My.Section>
          <My.Intro entity={entity} />
          <My.ChildPages 
            items={items} 
            tabletAutoColumns={3} 
            desktopAutoColumns={3} 
            desktopLargeAutoColumns={4} 
            spacing={4} 
            card={PageIntroductionCard}
          />
        </My.Section>
        {
          isEdition && 
          <>
            <My.Section>
              <My.Title>{t('Lenses')}</My.Title>
              <My.TypePages 
                items={lenses} 
                spacing={4} 
                tabletAutoColumns={3} 
                desktopAutoColumns={3} 
                desktopLargeAutoColumns={4} 
                card={PageIntroductionCard}
              />
            </My.Section>
            <My.Section>
              <My.Title>{t('Invited Contributions')}</My.Title>
              <My.TypePages 
                items={invitedContributions} 
                spacing={4} 
                tabletAutoColumns={3} 
                desktopAutoColumns={3} 
                desktopLargeAutoColumns={4} 
                card={PageIntroductionCard}
              />
            </My.Section>
            <My.Section>
              <My.Title>{t('Deep Dives')}</My.Title>
              <My.TypePages 
                items={deepDives} 
                tabletAutoColumns={3} 
                desktopAutoColumns={3} 
                desktopLargeAutoColumns={4} 
                spacing={4} 
                card={PageIntroductionCard}
              />
            </My.Section>
          </>
        }
        </My.View>
      }
    />
    </ExplorerProvider>
  )
};

PageExplorerParentLayout.defaultProps = PageExplorerParentLayoutDefaultProps;
PageExplorerParentLayout.cls = cls;