import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Color, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Debate } from '../../types';
const cls = classNames('debate-card-meta');

// -------- Types -------->

// -------- Props -------->

export type DebateCardMetaProps = Classable & HasChildren & { 
  entity?: Debate;
  color?: Color;
};

export const DebateCardMetaDefaultProps: Omit<DebateCardMetaProps, 'entity'> = {
  color: { value: 'text' },
};

// -------- Child Component Props -------->

type ContainerProps = {
  _color: Color;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    margin: 0;
    font-weight: 400;
    
    ${({ _color }: ContainerProps ) => css`
      color: ${theme.COLOR(_color?.value)};
    `}
  `,
});

// -------- Components -------->

const My = {
  Container: styled.h4<ContainerProps>`${ContainerStyles}`,
};

export const DebateCardMeta: Shapeable.FC<DebateCardMetaProps> = (props) => {
  const { className, children, entity, color } = props;

  const { subtitle } = entity;
  
  return (
    <My.Container _color={color} className={cls.name(className)}>
      {subtitle || children}
    </My.Container>
  )
};

DebateCardMeta.defaultProps = DebateCardMetaDefaultProps;
DebateCardMeta.cls = cls;